import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Box } from '@mui/system';
import { StyledHeading } from '../styles';
import { useLicences } from '../hooks';

const DetailLicencePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { getLicenceData, licence, getLicenceKeyData, sendLicenceEmailHandler } =
    useLicences();
  const { shortDateFormat } = useGlobalProperty();
  const { setAlert, samePageAlert } = useAlert();

  useEffect(() => {
    (async function getLicencesDataInit() {
      if (id) {
        await getLicenceData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleGetLicenceKey = async () => {
    try {
      if (id && licence) {
        await getLicenceKeyData(id, licence?.licenceId);
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  const licenceDetailColumn = licence && licence.expiryDate ? 12 / 5 : 3;

  const sendEmail = () => {
    if (licence && licence.uniqueId) {
      (async () => {
        try {
          await sendLicenceEmailHandler(licence.uniqueId as string);
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: 'Email notification has been sent to the administrator account.',
          });
        } catch (e: any) {
          console.error(e);
        }
      })();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'Licences', route: ERouteLinks.Licences }, 'Licence Details']}
      />
      {licence && id && (
        <>
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item lg={3} xs={12}>
              <Button
                label="EDIT"
                variant={EButtonVariants.contained}
                size={EButtonSizes.small}
                onClick={() => navigate(ERouteLinks.EditLicence.replace(':id', id))}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: '10px',
            }}
          >
            <StyledHeading>
              <Typography variant="h5" color={colors.primary900}>
                Purchaser Details
              </Typography>
            </StyledHeading>
            <Grid
              container
              sx={{
                border: `1px solid ${colors.primary25}`,
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <Grid
                container
                sx={{
                  border: `1px solid ${colors.primary25}`,
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: colors.primary25,
                  }}
                  px={2}
                  py={1}
                >
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {licence.purchaserName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container p={2} rowSpacing={2}>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Purchaser Name
                    </Typography>
                    <Typography variant="body2">{licence.purchaserName}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Country
                    </Typography>
                    <Typography variant="body2">{licence.country}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Contact Job Title
                    </Typography>
                    <Typography variant="body2">{licence.contactJobTitle}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Contact Full Name
                    </Typography>
                    <Typography variant="body2">{licence.contactFullName}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Contact Department
                    </Typography>
                    <Typography variant="body2">{licence.contactDepartment}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Contact Phone
                    </Typography>
                    <Typography variant="body2">{licence.contactPhone}</Typography>
                  </Grid>
                  <Grid item xs={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Contact Email
                    </Typography>
                    <Typography variant="body2">{licence.contactEmail}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              marginTop: '15px',
            }}
          >
            <StyledHeading>
              <Typography variant="h5" color={colors.primary900}>
                Licence Details
              </Typography>
            </StyledHeading>
            <Grid
              container
              sx={{
                border: `1px solid ${colors.primary25}`,
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: colors.primary25,
                }}
                px={2}
                py={1}
              >
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} md={12} lg={3}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {licence.licenceId}
                    </Typography>
                  </Grid>
                  <Grid item container xs={12} md={12} lg={9} spacing={2}>
                    <Grid item xs={12} md={4}>
                      {/* TO DO */}
                      <Button
                        label="download licence key"
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        onClick={handleGetLicenceKey}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/* TO DO */}
                      <Button
                        label="email licence key"
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        onClick={() => sendEmail()}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/* TO DO */}
                      <Button
                        label="replace licence"
                        variant={EButtonVariants.contained}
                        size={EButtonSizes.small}
                        onClick={() => navigate(ERouteLinks.ReplaceLicence.replace(':id', id))}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container p={2} rowSpacing={2}>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Licence ID
                  </Typography>
                  <Typography variant="body2">{licence.licenceId}</Typography>
                </Grid>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Licence Type
                  </Typography>
                  <Typography variant="body2">
                    {licence.licenceType && licence.licenceType.ONLINE && (
                      <Chips
                        text={licence.licenceType.ONLINE}
                        variant={EChipsVariants.warning}
                      />
                    )}
                    {licence.licenceType && licence.licenceType.OFFLINE && (
                      <Chips
                        text={licence.licenceType.OFFLINE}
                        variant={EChipsVariants.primary}
                      />
                    )}
                  </Typography>
                </Grid>
                {licence.expiryDate && (
                  <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Start Date
                    </Typography>
                    <Typography variant="body2">
                      {licence.startDate
                        ? formatDate(licence.startDate, shortDateFormat)
                        : '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Expiry Date
                  </Typography>
                  <Typography variant="body2">
                    {licence.expiryDate ? (
                      formatDate(licence.expiryDate, shortDateFormat)
                    ) : (
                      <Chips text="perpetual" variant={EChipsVariants.success100} />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Number of Users
                  </Typography>
                  <Typography variant="body2">
                    {licence.numberOfUsers || (
                      <Chips text="Unlimited" variant={EChipsVariants.success100} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export { DetailLicencePage };
