export const initialLoginFormValues = {
  email: '',
  password: '',
};

export const initialForgotPasswordFormValues = {
  email: '',
};

export const initialResetPasswordFormValues = {
  newPassword: '',
  newPasswordRepeat: '',
};

export const initialTwoFactorAuthFormValues = {
  authCode: '',
  isRememberDevice: false,
  trustedDeviceTimeOptionUniqueId: '',
};

export interface ITwoFactorAuthForm {
  authCode: number | string;
  isRememberDevice: boolean;
  trustedDeviceTimeOptionUniqueId: string;
}

export interface IFormElementProps {
  name: string;
  label?: string;
  control: any;
  errors: any;
  withValidation?: boolean;
  onChange?: (arg: any) => void;
  disabled?: boolean;
}

export interface IFormElementOptionProp {
  label: string;
  value: string | number;
}

export const initialExampleFormValues = {
  input: '',
  textarea: '',
  checkbox: false,
  switch: false,
  radioGroup: '',
  select: '',
};

export interface ISearchLicencesFormValues {
  licenceId: string;
  purchaserName: string;
  countryUniqueId: string;
}

export const initialLicencesSearchFormValues: ISearchLicencesFormValues = {
  licenceId: '',
  purchaserName: '',
  countryUniqueId: '',
};

export interface ILicencesFormValues {
  licenceId?: string;
  licenceType?: string;
  purchaserName?: string;
  countryUniqueId: string;
  contactJobTitle: string;
  contactFullName: string;
  contactDepartment: string;
  contactEmail: string;
  contactPhone: string;
  comments: string;
  startDate: string;
  expiryDate?: string;
  numberOfUsers: string;
  licenceAdministratorEmail: string;
  licenceAdministratorPassword: string;
  licenceFirstName: string;
  licenceLastName: string;
  licencePhonePrefixDictionaryUniqueId: string;
  licencePhoneNumber: string;
  languagesIds: string[];
  languagesIdsTmp?: { [key: string]: any };
  defaultLanguageId: string;

  [key: string]: any;
}

export interface ILicencesEditFormValues {
  licenceId?: string;
  licenceType?: string;
  purchaserName?: string;
  countryUniqueId: string;
  contactJobTitle: string;
  contactFullName: string;
  contactDepartment: string;
  contactEmail: string;
  contactPhone: string;
  comments: string;
  startDate: string;
  expiryDate?: string;
  numberOfUsers: string;
  licenceAdministratorPassword: string;
  languagesIds: string[];
  languagesIdsTmp?: { [key: string]: any };
  defaultLanguageId: string;

  [key: string]: any;
}

export const initialLicencesFormValues: ILicencesFormValues = {
  licenceType: '',
  purchaserName: '',
  countryUniqueId: '',
  contactJobTitle: '',
  contactFullName: '',
  contactDepartment: '',
  contactEmail: '',
  contactPhone: '',
  comments: '',
  startDate: '',
  expiryDate: '',
  numberOfUsers: '',
  licenceAdministratorEmail: '',
  licenceAdministratorPassword: '',
  licenceFirstName: '',
  licenceLastName: '',
  licencePhonePrefixDictionaryUniqueId: '',
  licencePhoneNumber: '',
  languagesIds: [],
  languagesIdsTmp: {},
  defaultLanguageId: '',
};

export interface IReplaceLicencesFormValues {
  licenceType: string;
  startDate: string;
  expiryDate?: string;
  numberOfUsers: string;
  licenceAdministratorEmail: string;
  licenceAdministratorPassword: string;
  licenceFirstName: string;
  licenceLastName: string;
  licencePhonePrefixDictionaryUniqueId: string;
  licencePhoneNumber: string;
}

export const initialReplaceLicencesFormValues = (
  licenceType: string,
): IReplaceLicencesFormValues => ({
  licenceType,
  startDate: '',
  expiryDate: '',
  numberOfUsers: '',
  licenceAdministratorEmail: '',
  licenceAdministratorPassword: '',
  licenceFirstName: '',
  licenceLastName: '',
  licencePhonePrefixDictionaryUniqueId: '',
  licencePhoneNumber: '',
});

export interface ILicencesUpdateRequest {
  contactJobTitle: string;
  contactFullName: string;
  contactDepartment: string;
  contactEmail: string;
  contactPhone: string;
  comments: string;
  expiryDate: string;
  numberOfUsers: string;
  languagesIds: string[];
  defaultLanguageId: string;
}

export const initialLicencesUpdateRequest: ILicencesUpdateRequest = {
  contactJobTitle: '',
  contactFullName: '',
  contactDepartment: '',
  contactEmail: '',
  contactPhone: '',
  comments: '',
  expiryDate: '',
  numberOfUsers: '',
  languagesIds: [],
  defaultLanguageId: '',
};

export interface ISearchUserFormValues {
  email: string;
  firstName: string;
  lastName: string;
  status: boolean | string;
  role: string;
  roleUniqueId: string;
  phoneNumber: string;
}

export const initialUserSearchFormValues: ISearchUserFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  status: '',
  role: '',
  roleUniqueId: '',
  phoneNumber: '',
};

export interface IUserFormValues {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumberCodeDictionaryUniqueId: string;
  phoneNumberValue: string;
  password: string;
}

export interface IUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
  password: string;
}

export const initialUserFormValues: IUserFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumberCodeDictionaryUniqueId: '',
  phoneNumberValue: '',
  password: '',
};

export interface IUserUpdateRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
}

export interface ISystemKeysFormValues {
  translationsFile: string;
}

export const initialSystemKeysFormValues: ISystemKeysFormValues = {
  translationsFile: '',
};

export interface ITranslationFormValuesRequest {
  request: {
    name: string;
    nativeName: string;
  };
  translationsFile: string;
}

export interface ITranslationFormValues {
  translationsFile?: string | null;
  nativeName: string;
  name: string;
  uploadedFile?: string;
}

export const initialTranslationFormValues: ITranslationFormValues = {
  translationsFile: '',
  nativeName: '',
  name: '',
  uploadedFile: '',
};
