import { EPerPages, ISortOptions } from 'models/table';
import { ISearchUserFormValues, IUserRequest, IUserUpdateRequest } from 'models/form';
import { queryStringWithSort } from 'helpers/request';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { IUser, IUsersResponse } from 'models/user';
import { ApiEndpoints } from 'models/apiEndpoints';

/**
 *
 * @param page
 * @param size
 * @param query
 * @param sort
 */
const getUsers = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchUserFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  let queryString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  queryString = queryString.replace('sort=status', 'sort=active');
  queryString = queryString.replace('sort=createdDate', 'sort=created');
  queryString = queryString.replace('sort=phoneNumber', 'sort=phoneNumber.value');

  const { data } = await apiClient.get<TResponse<IUsersResponse>>(
    `${ApiEndpoints.GET_USERS()}?${queryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param body
 */
const createUser = async (body: IUserRequest) =>
  apiClient.post<TResponse<IUser>>(ApiEndpoints.CREATE_USER(), body);

/**
 *
 * @param id
 */
const getUser = async (id: string): Promise<IUser | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_USER(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param uniqueId
 * @param user
 */
const updateUser = async (uniqueId: string, user: IUserUpdateRequest) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_USER(uniqueId), user);

  return !isResponseError(data);
};

/**
 *
 * @param id
 * @param active
 */
const setUserActive = async (id: string, active: boolean) => {
  const { data } = await apiClient.patch<TResponse<string>>(
    `${ApiEndpoints.ACTIVATE_USER(id)}?value=${active}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return true;
};

export { getUsers, createUser, getUser, updateUser, setUserActive };
