export enum EDictionaryTypes {
  Country = 'country_all',
  Caliber = 'caliber',
  Manufacturer = 'manufacturer',
  FirearmAction = 'firearm_action',
  FirearmType = 'firearm_type',
  Region = 'region',
  Gender = 'gender',
  OrganizationType = 'organization_type',
  LegalVisibility = 'legal_visibility',
  PhoneCode = 'phone_code',
  Legality = 'legality',
  Make = 'make',
  Model = 'model',
  State = 'state',
  BarrelType = 'barrel_type',
  Level = 'level',
  AddressType = 'address_type',
}

export enum ESpecificDictionaryTypes {
  Make = 'firearm/make',
  Model = 'firearm/model',
  Caliber = 'firearm/caliber',
  Manufacturer = 'firearm/manufacturer',
}

export interface IDictionary {
  uniqueId: string;
  name: string;
  type: string;
}

export interface IDictionaryResponse {
  content: IDictionary[];
}
