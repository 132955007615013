import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { isString } from 'helpers/guards';
import { Link } from 'react-router-dom';
import { StyledBreadcrumbs } from './styles';

interface IBreadcrumbsLink {
  label: string;
  route: ERouteLinks | any;
  state?: object;
}

interface IBreadcrumbs {
  items: (string | IBreadcrumbsLink)[];
}

const Breadcrumbs = ({ items }: IBreadcrumbs) => (
  <Stack spacing={2}>
    <StyledBreadcrumbs separator="/" aria-label="breadcrumbs">
      {items.map((item) =>
        isString(item) ? (
          <Typography variant="button" key={item}>
            {item}
          </Typography>
        ) : (
          <Link key={item.label} to={item.route} state={item.state}>
            {item.label}
          </Link>
        ),
      )}
    </StyledBreadcrumbs>
  </Stack>
);

export default Breadcrumbs;
