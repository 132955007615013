import React, { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import FormFooterLabel from 'components/atoms/FormFooterLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { IconButton, SxProps } from '@mui/material';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { getNestedErrorMessage } from 'components/molecules/FormInput/helper';
import { StyledTextField } from './styles';

export enum EFormInputTypes {
  text = 'text',
  textarea = 'textarea',
  password = 'password',
  number = 'number',
  email = 'email',
}

interface IFormInput extends IFormElementProps {
  placeholder?: string;
  footerLabel?: string;
  multiline?: boolean;
  minRows?: number;
  type?: EFormInputTypes;
  sx?: SxProps;
  className?: string;
  InputProps?: object;
  disabled?: boolean;
  setValue?: any;
  arrayName?: string;
  arrayIndex?: number;
  isClearable?: boolean;
}

export const FormInput = ({
  name,
  type = EFormInputTypes.text,
  label,
  footerLabel,
  placeholder,
  multiline,
  minRows,
  control,
  errors,
  sx,
  className,
  InputProps,
  disabled,
  setValue,
  arrayName,
  arrayIndex,
  isClearable = false,
}: IFormInput) => {
  const [error, setError] = useState<string>('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    let isError = false;
    if (name.includes('.') && !name.includes('[') && Object.keys(errors).length) {
      const keys = name.split('.');
      const errorMessage = getNestedErrorMessage(errors, keys);
      if (keys && errorMessage) {
        isError = true;
        setError(errorMessage);
      }
    } else {
      const errorMessage =
        arrayName && arrayIndex !== undefined && name
          ? errors?.[arrayName]?.[arrayIndex]?.[name]?.message
          : errors[name]?.message;

      isError = true;
      setError(errorMessage);
    }
    if (!isError) {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, name]);

  const renderEndAdornment = (field: ControllerRenderProps) => {
    if (type === EFormInputTypes.password) {
      return (
        <IconButton
          sx={{
            maxHeight: '32px',
            maxWidth: '32px',
          }}
          onClick={() => setIsPasswordVisible((data) => !data)}
        >
          <Icon type={isPasswordVisible ? EIconTypes.view : EIconTypes.hidden} />
        </IconButton>
      );
    }
    if (
      field.value !== '' &&
      !disabled &&
      setValue &&
      field.value !== undefined &&
      field.value !== null
    ) {
      return (
        <IconButton
          sx={{
            maxHeight: '32px',
            maxWidth: '32px',
            marginRight: '-12px',
          }}
          onClick={() => {
            setValue(name, '');
            setError('');
          }}
        >
          <Icon type={EIconTypes.clear} />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <StyledFormElementWrapper>
      <FormLabel name={name} label={label} disabled={disabled} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            className={className}
            disabled={disabled}
            InputProps={
              isClearable
                ? {
                    ...InputProps,
                    endAdornment: renderEndAdornment(field),
                  }
                : InputProps
            }
            type={isPasswordVisible ? 'text' : type}
            id={name}
            variant="outlined"
            placeholder={placeholder}
            multiline={multiline}
            minRows={minRows}
            error={!!errors[name]?.message || !!error}
            sx={sx}
          />
        )}
      />
      {footerLabel && <FormFooterLabel label={footerLabel} />}
      <FormErrorLabel label={errors[name]?.message} />
    </StyledFormElementWrapper>
  );
};
