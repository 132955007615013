import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { formatDate, longDateFormat } from 'utils/date';

interface IUserTableCard {
  data: ITableDataSource;
  activateUser: (id: string, active: boolean) => void;
  editUser: (id: string) => void;
  unbanUser: (id: string) => void;
  loggedUserId: string;
}

const UserTableCard = ({
  data,
  activateUser,
  editUser,
  unbanUser,
  loggedUserId,
}: IUserTableCard) => (
  <Grid
    container
    sx={{
      border: `1px solid ${colors.gray200}`,
      borderRadius: '4px',
      overflow: 'hidden',
      mt: 1,
      backgroundColor: colors.gray100,
    }}
  >
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Email
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: 'break-all', 'overflow-wrap': 'anywhere' }}>
        {data.email}
      </Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        First Name
      </Typography>
      <Typography variant="body2">{data.firstName}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Last Name
      </Typography>
      <Typography variant="body2">{data.lastName}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Phone Number
      </Typography>
      <Typography variant="body2">{data.phoneNumber}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Status
      </Typography>

      {data.blocked ? (
        <Chips text="Blocked" variant={EChipsVariants.warning} />
      ) : (
        <Chips
          text={data.status ? 'Inactive' : 'Active'}
          variant={data.status ? EChipsVariants.error : EChipsVariants.success100}
        />
      )}
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Last Login
      </Typography>
      <Typography variant="body2">{formatDate(data.lastLoginDate, longDateFormat)}</Typography>
    </Grid>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        m: {
          xs: 2,
          lg: 2,
        },
      }}
      spacing={2}
    >
      <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
          Actions
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={9} justifyContent="flex-end" columnGap={3}>
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Button
            label="Edit"
            variant={EButtonVariants.outlined}
            onClick={() => editUser(data.id as string)}
            size="small"
            fullWidth
          />
        </Grid>
        {loggedUserId !== data.id && (
          <Grid item xs={12} sm={3}>
            {data.blocked ? (
              <Button
                label="Unblock"
                variant={EButtonVariants.outlined}
                onClick={() => unbanUser(data.id as string)}
                size="small"
                fullWidth
              />
            ) : (
              <Button
                label={data.active ? 'Deactivate' : 'Activate'}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  data.active
                    ? activateUser(data.id as string, false)
                    : activateUser(data.id as string, true)
                }
                size="small"
                fullWidth
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

export { UserTableCard };
