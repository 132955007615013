import { ActionTypes } from 'state/actions/alert';
import { IAlert } from 'components/atoms/Alert';
import { IReducerAction } from 'models/context';

interface IState {
  authAlert: IAlert | null;
  samePageAlert: IAlert | null;
  nextPageAlert: IAlert | null;
  userAlert: IAlert | null;
  trustedDeviceAlert: IAlert | null;
}

export const initialState: IState = {
  authAlert: null,
  samePageAlert: null,
  nextPageAlert: null,
  userAlert: null,
  trustedDeviceAlert: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case ActionTypes.SET_AUTH_ALERT: {
      return { ...state, authAlert: payload };
    }
    case ActionTypes.CLEAR_AUTH_ALERT: {
      return { ...state, authAlert: null };
    }
    case ActionTypes.SET_SAME_PAGE_ALERT: {
      return { ...state, samePageAlert: payload };
    }
    case ActionTypes.CLEAR_SAME_PAGE_ALERT: {
      return { ...state, samePageAlert: null };
    }
    case ActionTypes.SET_NEXT_PAGE_ALERT: {
      return { ...state, nextPageAlert: payload };
    }
    case ActionTypes.CLEAR_NEXT_PAGE_ALERT: {
      return { ...state, nextPageAlert: null };
    }
    case ActionTypes.SET_USER_ALERT: {
      return { ...state, userAlert: payload };
    }
    case ActionTypes.CLEAR_USER_ALERT: {
      return { ...state, userAlert: null };
    }
    case ActionTypes.SET_TRUSTED_DEVICE_ALERT: {
      return { ...state, trustedDeviceAlert: payload };
    }
    case ActionTypes.CLEAR_TRUSTED_DEVICE_ALERT: {
      return { ...state, trustedDeviceAlert: null };
    }
    default:
      return state;
  }
};
