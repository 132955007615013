import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledTextField = styled(TextField)((props: any) => ({
  fieldset: {
    borderRadius: 4,
  },
  input: {
    fontSize: 14,
    ...(props.disabled
      ? { backgroundColor: colors.gray100 }
      : { backgroundColor: colors.white }),
    boxSizing: 'border-box',
  },
  textarea: {
    fontSize: 14,
  },
  'input::placeholder, textarea::placeholder': {
    fontSize: 16,
    fontWeight: 400,
    color: `${colors.gray300}`,
    fontFamily: 'NotoSansRegular, sans-serif',
    opacity: 1,
  },
  '& .MuiOutlinedInput-root': {
    marginBottom: 3,

    '& fieldset': {
      borderColor: colors.gray300,
    },
  },
}));
