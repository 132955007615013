import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledHeading = styled.div`
  background-color: ${colors.primary15};
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 20px 0;
`;

export const StyledDetail = styled(Grid)`
  background-color: ${colors.primary15};
  padding: 0px 20px 16px 20px;
  border-radius: 4px;
  margin: 20px 0;
  border: 1px solid ${colors.gray100};
`;

export const StyledLine = styled(Grid)`
  margin: 15px 15px 5px 15px;
  padding: 0px !important;
  border-bottom: 1px solid ${colors.primary25};
`;
