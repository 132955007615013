import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  FC_MANAGER_AUTH_ACCESS_TOKEN,
  FC_MANAGER_AUTH_REFRESH_TOKEN,
  refreshAuthToken,
} from 'requests/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { ApiEndpoints } from 'models/apiEndpoints';
import { ILoginBanInfo } from 'models/auth';

const REST_API_URL = process.env.REACT_APP_API_URL || 'http://3.72.173.29:8082/api/';

const apiClient: AxiosInstance = axios.create({
  baseURL: REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
  },
  timeout: 10000,
});

const AxiosInterceptor = () => {
  const navigate = useNavigate();
  const { setAlert, clearAlert } = useAlert();

  useEffect(() => {
    const reqInterceptor = async (req: AxiosRequestConfig) => {
      const token = localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN);
      if (token && req?.headers) {
        req.headers = {
          ...req.headers,
          ...(req.url?.indexOf(ApiEndpoints.REFRESH_AUTH_TOKEN()) === -1
            ? { Authorization: `Bearer ${token}` }
            : {}),
        };
      }

      return req;
    };
    const resInterceptor = async (response: AxiosResponse) => response;

    const errInterceptor = async (error: AxiosError) => {
      const isLoginError = error.request?.responseURL.indexOf(ApiEndpoints.LOG_IN()) !== -1;
      const isRefreshAuthTokenError =
        error.request?.responseURL.indexOf(ApiEndpoints.REFRESH_AUTH_TOKEN()) !== -1;
      const isChangePasswordError =
        error.request?.responseURL.indexOf(ApiEndpoints.ACCOUNT_CHANGE_PASSWORD()) !== -1;

      const authFailure = (text: string) => {
        setAlert(ActionTypes.SET_AUTH_ALERT, {
          text,
          variant: EAlertVariants.error,
        });
        localStorage.removeItem(FC_MANAGER_AUTH_ACCESS_TOKEN);
        localStorage.removeItem(FC_MANAGER_AUTH_REFRESH_TOKEN);
        navigate(ERouteLinks.Auth);
      };

      if (error.response?.status === 401 && isLoginError) {
        authFailure('The provided email or password is incorrect.');
      } else if (error.response?.status === 401) {
        authFailure(
          'Your session has expired and you have been logged out due to inactivity.',
        );
      } else if (
        error.response?.status === 403 &&
        !isLoginError &&
        !isRefreshAuthTokenError &&
        !isChangePasswordError
      ) {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem(FC_MANAGER_AUTH_REFRESH_TOKEN) || '';
        try {
          const res = await refreshAuthToken({
            refreshToken,
          });

          if (res?.accessToken && res?.refreshToken) {
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${res?.accessToken}`,
            };
            localStorage.setItem(FC_MANAGER_AUTH_ACCESS_TOKEN, res.accessToken);
            localStorage.setItem(FC_MANAGER_AUTH_REFRESH_TOKEN, res.refreshToken);
          }
          return await apiClient(originalRequest);
        } catch (e: any) {
          authFailure(
            'Your session has expired and you have been logged out due to inactivity.',
          );
        }
      } else if (error.response?.status === 403) {
        if (isChangePasswordError) {
          return Promise.reject(error);
        }

        if (isLoginError) {
          const loginBanInfo = error.response.data as ILoginBanInfo;
          if (loginBanInfo.attempts) {
            authFailure(
              'Your account has been blocked. Please contact your system administrator.',
            );
          }
        } else {
          const accessToken = localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN);
          const refreshToken = localStorage.getItem(FC_MANAGER_AUTH_REFRESH_TOKEN);

          if (!!accessToken && !!refreshToken) {
            navigate(ERouteLinks.AccessDenied);
          }
          authFailure(
            'Your session has expired and you have been logged out due to inactivity.',
          );
        }
        // eslint-disable-next-line no-dupe-else-if
      } else if (error.response?.status === 404) {
        navigate(ERouteLinks.NotFound);
      } else if (error.response?.status === 500) {
        navigate(ERouteLinks.ServerError);
      } else {
        clearAlert(ActionTypes.CLEAR_AUTH_ALERT);
      }

      return Promise.reject(error);
    };

    const interceptorReq = apiClient.interceptors.request.use(reqInterceptor, errInterceptor);
    const interceptorResp = apiClient.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => {
      apiClient.interceptors.response.eject(interceptorResp);
      apiClient.interceptors.response.eject(interceptorReq);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return null;
};

export { apiClient, AxiosInterceptor };
