import { EPageComponentTypes, ERouteLinks, IPageBuilderConfig } from 'models/route';

export const config: IPageBuilderConfig[] = [
  {
    componentType: EPageComponentTypes.AuthPage,
    route: ERouteLinks.Auth,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.AccountPage,
    route: ERouteLinks.Account,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.RemoveDevicePage,
    route: ERouteLinks.AccountRemoveDevice,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ChangePasswordPage,
    route: ERouteLinks.AccountChangePassword,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccountEditPage,
    route: ERouteLinks.AccountEdit,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ForgotPasswordPage,
    route: ERouteLinks.ForgotPassword,
    isProtected: false,
  },
  {
    componentType: EPageComponentTypes.ResetPasswordPage,
    route: ERouteLinks.ResetPassword,
    isProtected: false,
    nestedRoutes: [
      {
        componentType: EPageComponentTypes.ResetPasswordPage,
        route: ERouteLinks.ResetPasswordLink,
        isProtected: false,
      },
    ],
  },
  {
    componentType: EPageComponentTypes.LicencesPage,
    route: ERouteLinks.Licences,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddLicencePage,
    route: ERouteLinks.AddLicence,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditLicencePage,
    route: ERouteLinks.EditLicence,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.DetailLicencePage,
    route: ERouteLinks.DetailLicence,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ReplaceLicencePage,
    route: ERouteLinks.ReplaceLicence,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.UsersPage,
    route: ERouteLinks.Users,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddUserPage,
    route: ERouteLinks.AddUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditUserPage,
    route: ERouteLinks.EditUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ChangeStatusUserPage,
    route: ERouteLinks.ChangeStatusUser,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.TranslationsPage,
    route: ERouteLinks.Translations,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AddTranslationPage,
    route: ERouteLinks.AddTranslation,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.EditTranslationPage,
    route: ERouteLinks.EditTranslation,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.UpdateSystemKeysPage,
    route: ERouteLinks.UpdateSystemKeys,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.NotFoundPage,
    route: ERouteLinks.NotFound,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.AccessDeniedPage,
    route: ERouteLinks.AccessDenied,
    isProtected: true,
  },
  {
    componentType: EPageComponentTypes.ServerErrorPage,
    route: ERouteLinks.ServerError,
    isProtected: true,
  },
];
