import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useNavigate } from 'react-router-dom';
import { ILicencesFormValues, initialLicencesFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useLicences } from 'pages/LicencesPage/hooks';
import { useAlert } from 'models/alertContext';
import { LicenceForm } from '../LicenceForm';

const AddLicencePage = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<ILicencesFormValues | null>();
  const { handleCreate } = useLicences();
  const { setAlert, clearAlert } = useAlert();

  const handleSubmit = async (data: any) => {
    try {
      await handleCreate(data);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Licence has been created successfully. Email notification has been sent to the administrator account.',
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.Licences);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  useEffect(() => {
    const formInitialValues = { ...initialLicencesFormValues };
    setInitialValues(formInitialValues);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'Licences', route: ERouteLinks.Licences }, 'Add Licences']}
      />
      <StyledPageTitle variant="h4">Add Licences</StyledPageTitle>
      <TableFormWrapper>
        <LicenceForm
          onCancel={() => navigate(ERouteLinks.Licences)}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
        />
      </TableFormWrapper>
    </>
  );
};

export { AddLicencePage };
