import React from 'react';
import { Controller } from 'react-hook-form';

import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { colors } from 'theme/colors';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { StyledFormCheckboxWrapper } from 'components/molecules/FormCheckbox/styles';
import { IFormElementProps } from 'models/form';

export const FormCheckbox = ({
  name,
  label,
  control,
  errors,
  withValidation = true,
  onChange,
  disabled = false,
}: IFormElementProps) => (
  <StyledFormCheckboxWrapper>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          control={
            <Checkbox
              checked={field.value ?? false}
              sx={{
                color: disabled ? colors.gray300 : colors.primary500,
                '&.Mui-checked': {
                  color: disabled ? colors.gray300 : colors.primary500,
                },
              }}
              onClick={onChange}
              disabled={disabled}
            />
          }
          label={label !== undefined ? <Typography variant="body2">{label}</Typography> : ''}
        />
      )}
    />
    {withValidation && <FormErrorLabel label={errors[name]?.message} />}
  </StyledFormCheckboxWrapper>
);
