import React, { useState } from 'react';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Drawer } from 'components/organisms/Drawer';
import { StyledHamburgerMenu } from './styles';

const HamburgerMenu = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledHamburgerMenu>
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.menu}
          sizePX={EButtonSizesPX.px32}
          onClick={() => setOpen(!open)}
        />
      </StyledHamburgerMenu>

      {open && <Drawer open={open} setOpen={setOpen} mobile={true} />}
    </>
  );
};

export { HamburgerMenu };
