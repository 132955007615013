import { IDictionary } from 'models/dictionary';
import { IFormElementOptionProp } from 'models/form';

export const mapDictionaryToOptionProp = (
  dictionary: IDictionary[] | null,
  prefix?: string,
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary.map((item) => ({
    label: prefix ? `${prefix}${item.name}` : item.name,
    value: item.uniqueId,
  }));
};

export const mapSpecificDictionaryToOptionProp = (
  dictionary: string[] | null,
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary.map((item) => ({
    label: item,
    value: item,
  }));
};

export interface IParsedDictionary {
  [id: string]: string;
}

export const parseDictionary = (dictionary: IDictionary[] | null, key: keyof IDictionary) => {
  const result: IParsedDictionary = {};

  if (!dictionary) {
    return result;
  }

  dictionary.forEach((dict) => {
    if (
      Object.prototype.hasOwnProperty.call(dict, 'uniqueId') &&
      Object.prototype.hasOwnProperty.call(dict, key)
    ) {
      result[dict.uniqueId] = <string>dict[key];
    }
  });

  return result;
};
