export interface INestedRoute {
  componentType: EPageComponentTypes;
  route: ERouteLinks;
  isProtected: boolean;
}

export interface IPageBuilderConfig {
  componentType: EPageComponentTypes;
  route: ERouteLinks;
  isProtected: boolean;
  nestedRoutes?: INestedRoute[];
}

export enum ERouteLinks {
  Auth = '/',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  ResetPasswordLink = ':token',
  Dashboard = '/dashboard',
  NotFound = '*',
  AccessDenied = '/access-denied',
  ServerError = '/internal-server-error',
  Licences = '/licences',
  AddLicence = '/licence/add',
  EditLicence = '/licence/edit/:id',
  DetailLicence = '/licence/:id',
  ReplaceLicence = '/licence/replace/:id',
  Users = '/users',
  AddUser = '/users/add',
  EditUser = '/users/edit/:id',
  ChangeStatusUser = '/users/status/:statusAction/:id',
  Translations = '/translations',
  EditTranslation = '/translations/:id',
  AddTranslation = '/translations/add',
  UpdateSystemKeys = '/translations/system-keys/:id',
  Account = '/account',
  AccountRemoveDevice = '/account/remove-device/:id',
  AccountChangePassword = '/account/change-password',
  AccountEdit = '/account/edit',
}

export enum EPageComponentTypes {
  AuthPage = 'AuthPage',
  AccountPage = 'AccountPage',
  RemoveDevicePage = 'RemoveDevicePage',
  AccountEditPage = 'EditAccountPage',
  ChangePasswordPage = 'ChangePasswordPage',
  ForgotPasswordPage = 'ForgotPasswordPage',
  ResetPasswordPage = 'ResetPasswordPage',
  ResetPasswordLink = 'ResetPasswordPage',
  DashboardPage = 'DashboardPage',
  NotFoundPage = 'NotFoundPage',
  AccessDeniedPage = 'AccessDeniedPage',
  ServerErrorPage = 'ServerErrorPage',
  LicencesPage = 'LicencesPage',
  UsersPage = 'UsersPage',
  AddUserPage = 'AddUserPage',
  EditUserPage = 'EditUserPage',
  ChangeStatusUserPage = 'ChangeStatusUserPage',
  TranslationsPage = 'TranslationsPage',
  AddTranslationPage = 'AddTranslationPage',
  EditTranslationPage = 'EditTranslationPage',
  UpdateSystemKeysPage = 'UpdateSystemKeysPage',
  AddLicencePage = 'AddLicencePage',
  EditLicencePage = 'EditLicencePage',
  DetailLicencePage = 'DetailLicencePage',
  ReplaceLicencePage = 'ReplaceLicencePage',
}
