import * as yup from 'yup';

const useTranslationFormSchema = (isEdit = false) => {
  let schema = yup.object({
    name: yup
      .string()
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    nativeName: yup
      .string()
      .nullable()
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    translationsFile: yup.string().nullable(),
  });
  if (!isEdit) {
    schema = schema.test('translationsFileTest', '', (obj) => {
      if (!obj.translationsFile) {
        return new yup.ValidationError('This field is required.', null, 'translationsFile');
      }

      return true;
    });
  }

  return { schema };
};

export { useTranslationFormSchema };
