import React from 'react';
import { StyledFormLabel } from './styles';

interface IFormLabel {
  name: string;
  label?: string;
  disabled?: boolean;
}

const FormLabel = ({ name, label, disabled }: IFormLabel) => (
  <StyledFormLabel htmlFor={name} disabled={disabled}>
    {label}
  </StyledFormLabel>
);

export default FormLabel;
