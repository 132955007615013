import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementOptionProp, IFormElementProps } from 'models/form';
import { IconButton } from '@mui/material';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import { StyledFormControl, StyledInnerWrapper, StyledSelect } from './styles';

interface IFormSelect extends IFormElementProps {
  options: IFormElementOptionProp[];
  disabled?: boolean;
  withValidation?: boolean;
  className?: string;
  canBeEmpty?: boolean;
  setValue?: any;
  emptyPlaceholder?: boolean;
}

export const FormSelect = ({
  name,
  label,
  options,
  control,
  errors,
  disabled,
  className,
  setValue,
  withValidation = true,
  canBeEmpty = false,
  emptyPlaceholder = false,
}: IFormSelect) => (
  <StyledFormElementWrapper className={className}>
    <FormLabel name={name} label={label} disabled={disabled} />
    <StyledInnerWrapper>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledFormControl className={className} error={!!errors[name]?.message}>
            <StyledSelect
              {...field}
              displayEmpty
              labelId={`${name}-label`}
              id={name}
              disabled={disabled}
              placeholder={emptyPlaceholder ? '' : 'Select'}
              sx={{
                ...(field.value === '' ? { color: colors.gray300 } : {}),
                '& .MuiSelect-iconOutlined': {
                  display: canBeEmpty && !disabled && field.value !== '' ? 'none' : '',
                },
              }}
              endAdornment={
                field.value !== '' &&
                canBeEmpty &&
                setValue &&
                !disabled && (
                  <IconButton
                    sx={{
                      maxHeight: '32px',
                      maxWidth: '32px',
                    }}
                    onClick={() => setValue(name, '')}
                    id="clear"
                  >
                    <Icon type={EIconTypes.clear} />
                  </IconButton>
                )
              }
            >
              <MenuItem
                disabled={!canBeEmpty}
                value=""
                style={{
                  display: 'none',
                }}
              >
                {emptyPlaceholder ? '' : 'Select'}
              </MenuItem>
              {options.map((option: IFormElementOptionProp) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </StyledFormControl>
        )}
      />
    </StyledInnerWrapper>
    {withValidation && <FormErrorLabel label={errors[name]?.message} />}
  </StyledFormElementWrapper>
);
