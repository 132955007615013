import { DictionaryActionTypes } from 'state/actions/dictionary';
import { IReducerAction } from 'models/context';
import { IDictionary } from 'models/dictionary';

interface IState {
  gender: IDictionary[] | null;
  organizationType: IDictionary[] | null;
  legalVisibility: IDictionary[] | null;
  make: string[] | null;
  model: string[] | null;
  caliber: string[] | null;
  state: IDictionary[] | null;
  region: IDictionary[] | null;
  country: IDictionary[] | null;
  phoneCode: IDictionary[] | null;
  addressType: IDictionary[] | null;
  legality: IDictionary[] | null;
  manufacturer: string[] | null;
}

export const initialState: IState = {
  gender: null,
  organizationType: null,
  legalVisibility: null,
  make: null,
  model: null,
  caliber: null,
  state: null,
  region: null,
  country: null,
  phoneCode: null,
  addressType: null,
  legality: null,
  manufacturer: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case DictionaryActionTypes.SET_GENDER: {
      return { ...state, gender: payload };
    }
    case DictionaryActionTypes.SET_ORGANIZATION_TYPE: {
      return { ...state, organizationType: payload };
    }
    case DictionaryActionTypes.SET_LEGAL_VISIBILITY: {
      return { ...state, legalVisibility: payload };
    }
    case DictionaryActionTypes.SET_MAKE: {
      return { ...state, make: payload };
    }
    case DictionaryActionTypes.SET_MODEL: {
      return { ...state, model: payload };
    }
    case DictionaryActionTypes.SET_CALIBER: {
      return { ...state, caliber: payload };
    }
    case DictionaryActionTypes.SET_STATE: {
      return { ...state, state: payload };
    }
    case DictionaryActionTypes.SET_REGION: {
      return { ...state, region: payload };
    }
    case DictionaryActionTypes.SET_COUNTRY: {
      return { ...state, country: payload };
    }
    case DictionaryActionTypes.SET_PHONE_CODE: {
      return { ...state, phoneCode: payload };
    }
    case DictionaryActionTypes.SET_ADDRESS_TYPE: {
      return { ...state, addressType: payload };
    }
    case DictionaryActionTypes.SET_LEGALITY: {
      return { ...state, legality: payload };
    }
    case DictionaryActionTypes.SET_MANUFACTURER: {
      return { ...state, manufacturer: payload };
    }
    default:
      return state;
  }
};
