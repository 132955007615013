import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import {
  EDictionaryTypes,
  ESpecificDictionaryTypes,
  IDictionaryResponse,
} from 'models/dictionary';

const getDictionary = async (type: EDictionaryTypes) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_DICTIONARY(type),
    {
      headers: {
        'Accept-Language': 'en',
      },
    },
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getSpecificDictionary = async (type: ESpecificDictionaryTypes) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_SPECIFIC_DICTIONARY(type),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { getDictionary, getSpecificDictionary };
