import React, { ReactElement, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { initialLoginFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/LoginPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { useAuth } from 'models/authContext';
import { IAuthBodyRequest, IAuthResponse, ILoginForm } from 'models/auth';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/Logo.svg';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { Alert } from 'components/atoms/Alert';
import { AuthTemplate } from 'components/templates/AuthTemplate';
import { IData } from 'pages/AuthPage';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { colors } from 'theme/colors';

export const FC_SYSTEM_DEVICE_ID = 'FC_SYSTEM_DEVICE_ID';

export interface ILoginPageProps {
  changePage: (p: number) => void;
  changeDataCallback: (p: IData) => void;
}

const LoginPage = ({ changePage, changeDataCallback }: ILoginPageProps): ReactElement => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialLoginFormValues, resolver: yupResolver(schema) });
  const { logIn, authMessage, isLoginLoading, clearAuthErrors } = useAuth();
  const { authAlert, clearAlert } = useAlert();
  useEffect(
    () => () => {
      clearAuthErrors();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_AUTH_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = (data: ILoginForm) => {
    const body: IAuthBodyRequest = {
      ...data,
      deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
    };
    logIn(body, (res: IAuthResponse) => {
      if (res.accessToken) {
        navigate(ERouteLinks.Licences);
        changePage(3);
      } else if (res.phoneNumber) {
        changePage(2);
        const phoneNumber = res.phoneNumber.toString();
        const numberEndPart = phoneNumber.slice(phoneNumber.length - 3);
        changeDataCallback({
          ...data,
          phoneNumber: `*********${numberEndPart}`,
        });
      }
    });
  };
  return (
    <AuthTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sm={9}
        md={6}
        lg={5}
        xl={6}
        px={2}
        pb={5}
      >
        <Grid item xl={6} container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            sx={{ width: { xs: '120px', sm: '200px', xl: '220px' } }}
          >
            <Logo src={LogoImage} alt="Logo" />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography
              variant="button"
              textTransform="uppercase"
              color={colors.primary500}
              my={1}
            >
              Manager
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {authAlert ? (
              <Box mb={2}>
                <Alert text={authAlert.text} variant={authAlert.variant} />
              </Box>
            ) : (
              <Box
                my={1}
                sx={{
                  visibility: authMessage.text && !authAlert ? 'visible' : 'hidden',
                }}
              >
                <Alert text={authMessage.text} variant={authMessage.variant} />
              </Box>
            )}
            <Typography variant="body1" textAlign="center" mb={2}>
              Log in to access FireCycle Manager
              <br />
              using your email address and password.
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <FormInput
                  name="email"
                  label="Email"
                  placeholder="name@domain.com"
                  control={control}
                  errors={errors}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: { xs: 32, sm: 40 },
                    },
                  }}
                />
                <FormInput
                  name="password"
                  type={EFormInputTypes.password}
                  label="Password"
                  placeholder="Password"
                  control={control}
                  errors={errors}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: { xs: 32, sm: 40 },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={7} xl={12} container justifyContent="center">
                <Grid item xs={12} mb={1}>
                  <Button
                    type="submit"
                    label="Login"
                    variant={EButtonVariants.contained}
                    isLoading={isLoginLoading}
                    fullWidth
                  />
                </Grid>
                <RouterLink to={ERouteLinks.ForgotPassword} label="Forgot password?" />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { LoginPage };
