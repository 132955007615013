import { EIconTypes } from 'constants/Icons';
import { ERouteLinks } from 'models/route';

export interface IMenuItem {
  label: string;
  icon: EIconTypes;
  url: string;
  isNotificationItem?: boolean;
  items?: string[];
}

export const MenuItems: IMenuItem[] = [
  {
    label: 'Licences',
    icon: EIconTypes.licences,
    url: ERouteLinks.Licences,
    items: ['/licence'],
  },
  {
    label: 'Users',
    icon: EIconTypes.users,
    url: ERouteLinks.Users,
  },
  {
    label: 'Translations',
    icon: EIconTypes.translations,
    url: ERouteLinks.Translations,
  },
];
