import { format, isAfter, isEqual, parseISO } from 'date-fns';

type TDate = Date | number | null | string;

export const longDateFormat = 'dd/MM/yyyy hh:mm';

export const isDateAfter = (date: TDate, dateToCompare: TDate) => {
  if (typeof date !== 'string' && date && typeof dateToCompare !== 'string' && dateToCompare) {
    return isAfter(date, dateToCompare);
  }
  return null;
};

export const isDateEqual = (date: TDate, dateToCompare: TDate) => {
  if (typeof date !== 'string' && date && typeof dateToCompare !== 'string' && dateToCompare) {
    return isEqual(date, dateToCompare);
  }
  return null;
};

// eslint-disable-next-line no-restricted-globals
export const isValidDate = (date: any) => !isNaN(Date.parse(date));

export function formatDate(date: string | null | undefined, dateFormat: string): string {
  if (date === null || date === undefined || date.trim() === '') {
    return '-';
  }
  const parseTimezone = date.match(/\[.+?]/g);
  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  return format(parseISO(date.slice(0, -timezone.length)), dateFormat);
}

export function simpleFormatDate(date: Date | null | undefined, dateFormat: string): string {
  if (date === null || date === undefined) {
    return '-';
  }
  return format(date, dateFormat);
}

export const formatDateTimeToIso = (
  dateTime: string | Date | number | undefined | null,
  endDay?: boolean,
) => {
  if (dateTime === undefined || dateTime === null || dateTime === '') {
    return '';
  }
  const dateTimeString = dateTime.toString();

  const newDate = new Date(dateTimeString);
  if (endDay) {
    newDate.setHours(23, 59, 59);
  }
  return `${newDate.toISOString().slice(0, -5)}Z`;
};

export const getDateFromISO = (date: string | null) => {
  if (date === null || date === undefined || date.trim() === '') {
    return '';
  }
  const parseTimezone = date.match(/\[.+?]/g);
  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  return parseISO(date.slice(0, -timezone.length));
};

export enum EAlertTime {
  SHORT = 7000,
  LONG = 12000,
}

export const clearAlertTimeout = (
  clearAlert: (type: string) => void,
  type: string,
  time: EAlertTime,
) => {
  const timeoutId = setTimeout(() => {
    clearAlert(type);
  }, time);

  return () => clearTimeout(timeoutId);
};
