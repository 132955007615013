import styled from '@emotion/styled';

const PageWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 34px);
  flex-direction: column;
  padding: 24px;
`;

export { PageWrapper };
