import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initialReplaceLicencesFormValues, IReplaceLicencesFormValues } from 'models/form';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReplaceLicencesFormSchema } from 'pages/LicencesPage/schemaValidation';
import { useForm } from 'react-hook-form';
import { useLicences } from 'pages/LicencesPage/hooks';
import { colors } from 'theme/colors';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { clearAlertTimeout, EAlertTime } from 'utils/date';
import { Box } from '@mui/system';
import { StyledHeading } from '../styles';

export interface IReplaceLicencesForm {
  onSubmit: (arg: IReplaceLicencesFormValues) => void;
  licenceType: string;
}

const licenceTypeOpts = [
  {
    label: 'cloud / online',
    value: 'ONLINE',
  },
  {
    label: 'on-premise / offline',
    value: 'OFFLINE',
  },
];

const ReplaceLicenceForm = ({ onSubmit, licenceType }: IReplaceLicencesForm) => {
  const { loadDictionaries, phoneCode, handleGeneratePassword } = useLicences();
  const { schema } = useReplaceLicencesFormSchema();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { setAlert, samePageAlert } = useAlert();
  const { clearAlert } = useAlert();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm<IReplaceLicencesFormValues>({
    defaultValues: initialReplaceLicencesFormValues(licenceType),
    resolver: yupResolver(schema),
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function init() {
      await loadDictionaries();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(initialReplaceLicencesFormValues(licenceType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const onSubmitForm = async (data: IReplaceLicencesFormValues) => {
    setIsSubmitLoading(true);
    await onSubmit(data);
    setIsSubmitLoading(false);
  };

  const generatePassword = async () => {
    const newPassword = await handleGeneratePassword();
    if (newPassword) {
      setValue('licenceAdministratorPassword', newPassword);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Password has been generated successfully.',
      });
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
    } else {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Something went wrong.',
        variant: EAlertVariants.error,
      });
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <StyledHeading>
        <Typography variant="h5" color={colors.primary900}>
          New Licence Details
        </Typography>
      </StyledHeading>

      <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
        <Grid item xs={12} lg={6}>
          <FormSelect
            name="licenceType"
            options={licenceTypeOpts}
            label="Licence Type*"
            control={control}
            disabled={true}
            errors={errors}
          />
        </Grid>
      </Grid>
      <Grid item container rowSpacing={0} columnSpacing={2} lg={12}>
        <Grid item xs={12} md={6} lg={3}>
          <FormDatePicker
            name="startDate"
            openTo="year"
            label="Start Date*"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormDatePicker
            name="expiryDate"
            openTo="year"
            label="Expiry Date"
            footerLabel="Empty value means perpetual licence"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormInput
            name="numberOfUsers"
            type={EFormInputTypes.number}
            label="Number of Users"
            footerLabel="Empty value means unlimited number of users"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
      </Grid>
      <Grid item container rowSpacing={0} columnSpacing={2} lg={12} alignItems="flex-end">
        <Grid item xs={12} md={12} lg={6}>
          <FormInput
            name="licenceAdministratorEmail"
            type={EFormInputTypes.email}
            label="Licence Administrator Email*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} sm={7} md={6} lg={3}>
          <FormInput
            name="licenceAdministratorPassword"
            label="Licence Administrator Password*"
            disabled={true}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={6} lg={3} alignSelf="center" sx={{ paddingTop: '6px' }}>
          <Button
            fullWidth
            label="generate password"
            variant={EButtonVariants.outlined}
            size={EButtonSizes.small}
            onClick={generatePassword}
          />
        </Grid>
      </Grid>
      <Grid item container rowSpacing={0} columnSpacing={2} lg={12} alignItems="flex-end">
        <Grid item xs={12} md={6} lg={3}>
          <FormInput
            name="licenceFirstName"
            label="First Name*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormInput
            name="licenceLastName"
            label="Last Name*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={3} md={3} lg={1.5}>
          <FormSelect
            name="licencePhonePrefixDictionaryUniqueId"
            label="Phone Prefix*"
            options={mapDictionaryToOptionProp(phoneCode)}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={9} md={9} lg={4.5}>
          <FormInput
            name="licencePhoneNumber"
            label="Phone Number*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          {samePageAlert && (
            <Box mb={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} lg={9} container columnSpacing={3} rowGap={3} mt={2}>
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              fullWidth
              type="submit"
              label="replace licence"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Button
              fullWidth
              label="Cancel"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(ERouteLinks.DetailLicence.replace(':id', id || ''))}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item mt={2} xs={12}>
        <Typography variant="caption">*Mandatory field</Typography>
      </Grid>
    </form>
  );
};

export { ReplaceLicenceForm };
