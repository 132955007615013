import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { IUser } from 'models/user';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { formatDate, longDateFormat } from 'utils/date';

export const mapUsersToDataSource = (roles: IUser[]): ITableDataSource[] =>
  roles.map((user) => ({
    id: user.uniqueId,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    createdDate: user.created,
    lastLoginDate: user.lastLoginDate,
    active: user.status,
    blocked: user.blocked,
    phoneNumber: user.phoneNumber,
  }));

interface IUsersTableColumns {
  handleEdit: (arg: string) => void;
  handleActivate: (arg: string) => void;
  handleDeactivate: (arg: string) => void;
  handleUnban: (arg: string) => void;
  loggedUserId: string;
}

export const usersTableColumns = ({
  handleEdit,
  handleActivate,
  handleDeactivate,
  handleUnban,
  loggedUserId,
}: IUsersTableColumns): ITableColumns[] => [
  {
    id: '4eaea4ee-d36f-400b-895b-231c81f091cf',
    key: 'email',
    title: 'Email',
  },
  {
    id: '4eaea4ee-d36f-410b-895b-231c81f091cf',
    key: 'firstName',
    title: 'First Name',
  },
  {
    id: '4eaea4ee-d36f-420b-895b-231c81f091cf',
    key: 'lastName',
    title: 'Last Name',
  },
  {
    id: '4eaea4ee-d36f-430b-895b-231c81f091cf',
    key: 'phoneNumber',
    title: 'Phone Number',
    width: '170',
  },
  {
    id: '4eaea4ee-d36f-440b-895b-231c81f091cf',
    key: 'active',
    title: 'Status',
    isSortable: false,
    isCenterTableHead: true,
    align: 'center',
    render: (_data, props) => {
      if (props.blocked) {
        return <Chips text="Blocked" variant={EChipsVariants.warning} />;
      }
      return (
        <Chips
          text={props.active ? 'Active' : 'Inactive'}
          variant={props.active ? EChipsVariants.success100 : EChipsVariants.error}
        />
      );
    },
  },
  {
    id: '4eaea4ee-d36f-460b-895b-231c81f091cf',
    key: 'lastLoginDate',
    title: 'Last Login',
    width: '180',
    render: (_data, props) =>
      props.lastLoginDate ? formatDate(props.lastLoginDate, longDateFormat) : '-',
  },
  {
    id: '4eaea4ee-d36f-500b-895b-231c81f091cf',
    key: '',
    title: 'Action',
    isSortable: false,
    width: '110px',
    align: 'center',
    render: (_data, props) => (
      <>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
        />
        {props.blocked
          ? loggedUserId !== props.id && (
              <Button
                variant={EButtonVariants.outlined}
                tableButton={true}
                icon={EIconTypes.checkmark}
                onClick={() => handleUnban(props.id)}
              />
            )
          : loggedUserId !== props.id && (
              <Button
                variant={EButtonVariants.outlined}
                tableButton={true}
                icon={props.active ? EIconTypes.shutDown : EIconTypes.checkmark}
                onClick={() =>
                  props.active ? handleDeactivate(props.id) : handleActivate(props.id)
                }
              />
            )}
      </>
    ),
  },
];
