import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledFormErrorLabel = styled.span`
  min-height: 16px;
  margin-bottom: 2px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.error700};
`;
