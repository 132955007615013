import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EUserStatus, IUser } from 'models/user';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { getUser, setUserActive } from 'requests/user';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAuth } from 'models/authContext';

const ChangeStatusUserPage = () => {
  const { id = '', statusAction } = useParams<{ id: string; statusAction: EUserStatus }>();
  const [user, setUser] = useState<IUser>();
  const { phoneCode, getPhoneCode } = useDictionary();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { user: loggedUser } = useAuth();

  useEffect(() => {
    (async function getUserDataInit() {
      if (id && loggedUser?.userId) {
        if (id !== loggedUser.userId) {
          const response = await getUser(id);
          if (response) {
            setUser(response);
          }
          if (!phoneCode) {
            getPhoneCode();
          }
        } else {
          navigate(-1);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, loggedUser]);

  const activateUser = async () => {
    const result = await setUserActive(id, statusAction === EUserStatus.Activate);
    if (result) {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: `User has been ${
          statusAction === EUserStatus.Activate ? 'activated' : 'deactivated'
        } successfully.`,
      });
      navigate(ERouteLinks.Users);
    }
  };

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: 'Users',
            route: ERouteLinks.Users,
          },
          statusAction === EUserStatus.Activate ? 'Activate User' : 'Deactivate User',
        ]}
      />
      {user && (
        <>
          <Box sx={{ mb: 1 }}>
            <StyledPageTitle variant="h4">
              {statusAction === EUserStatus.Activate ? 'Activate User' : 'Deactivate User'}
            </StyledPageTitle>
          </Box>

          <Box my={3}>
            <Alert
              text={`Are you sure you want to ${
                statusAction === EUserStatus.Activate ? 'activate' : 'deactivate'
              } this user?`}
              variant={EAlertVariants.warning}
            />
          </Box>

          <Grid
            container
            sx={{
              border: `1px solid ${colors.primary25}`,
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: colors.primary25,
              }}
              px={2}
              py={1}
            >
              <Grid item xs={12} lg={6}>
                <Typography variant="h4" sx={{ fontWeight: 600 }}>
                  {user.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container p={2} rowSpacing={2}>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  First Name
                </Typography>
                <Typography variant="body2">{user.firstName}</Typography>
              </Grid>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Last Name
                </Typography>
                <Typography variant="body2">{user.lastName}</Typography>
              </Grid>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Phone Number
                </Typography>
                <Typography variant="body2">{`${
                  parsedPhoneCodeDictionary?.[user.phoneNumber.codeDictionaryUniqueId]
                    ? `${
                        parsedPhoneCodeDictionary?.[user.phoneNumber.codeDictionaryUniqueId]
                      } ${user.phoneNumber.value}`
                    : ''
                }`}</Typography>
              </Grid>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Last Logged
                </Typography>
                <Typography variant="body2">{user.lastLoginDate}</Typography>
              </Grid>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Status
                </Typography>
                <div>
                  <Chips
                    text={user.active ? 'Active' : 'Inactive'}
                    variant={user.active ? EChipsVariants.success100 : EChipsVariants.error}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label="Yes"
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={() => activateUser()}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label="No"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.Users)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { ChangeStatusUserPage };
