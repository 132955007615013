import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import {
  ESortDirectionTypes,
  ISortOptions,
  ITableColumns,
  ITableDataSource,
} from 'models/table';
import { isFunction } from 'helpers/guards';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { colors } from 'theme/colors';
import { Loader } from 'components/atoms/Loader';
import {
  StyledTable,
  StyledTableBody,
  StyledTableBodyCell,
  StyledTableBodyRow,
  StyledTableContainer,
  StyledTableHead,
  StyledTableHeadCell,
  StyledTableHeadRow,
} from './styles';

interface ITable {
  columns: ITableColumns[];
  dataSource: ITableDataSource[] | null;
  onSort?: ({ property, direction }: ISortOptions) => void;
  isSortLoaded?: boolean;
}

const Table = ({
  columns,
  dataSource,
  onSort = () => {},
  isSortLoaded = true,
}: ITable): ReactElement => {
  const [modifiedColumns, setModifiedColumns] = useState<any[]>(columns);

  useEffect(() => {
    setModifiedColumns((previousModifiedColumns) =>
      columns.map((column: ITableColumns) => {
        const previousColumn = previousModifiedColumns.find(({ key }) => key === column.key);

        return {
          ...column,
          title: column.title,
          property: column.key,
          direction: previousColumn?.direction || ESortDirectionTypes.ASC,
        };
      }),
    );
  }, [columns]);

  const handleSort = (key: string) => {
    const sortableOptions: ISortOptions = {
      property: '',
      direction: ESortDirectionTypes.ASC,
    };

    setModifiedColumns(
      modifiedColumns.map((column) => {
        if (column.key === key) {
          const direction =
            column.direction === ESortDirectionTypes.ASC
              ? ESortDirectionTypes.DESC
              : ESortDirectionTypes.ASC;

          sortableOptions.property = column.key;
          sortableOptions.direction = direction;
          return { ...column, direction };
        }
        column.direction = ESortDirectionTypes.ASC;
        return column;
      }),
    );

    onSort(sortableOptions);
  };

  const defineSortingType = (columnKey: string) => {
    const { direction } = modifiedColumns.find((column) => column.key === columnKey);
    return direction;
  };

  return isSortLoaded ? (
    <StyledTableContainer>
      <StyledTable sx={{ minWidth: 650 }}>
        <StyledTableHead>
          <StyledTableHeadRow>
            {modifiedColumns.map(
              (col: ITableColumns): ReactElement => (
                <StyledTableHeadCell key={col.id}>
                  <div
                    tabIndex={0}
                    onClick={() => col.isSortable !== false && handleSort(col.key)}
                    role="button"
                    style={
                      col.isCenterTableHead
                        ? { display: 'flex', justifyContent: 'center', padding: 0 }
                        : {}
                    }
                  >
                    <Typography variant="subtitle2">
                      {isFunction(col.title) ? col.title(col) : col.title}
                    </Typography>
                    {col.isSortable !== false && (
                      <Icon
                        type={
                          defineSortingType(col.key) === ESortDirectionTypes.ASC
                            ? EIconTypes.smallDropdownUp
                            : EIconTypes.smallDropdownDown
                        }
                      />
                    )}
                  </div>
                </StyledTableHeadCell>
              ),
            )}
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {!!dataSource &&
            dataSource.map((data: ITableDataSource, index): ReactElement => {
              if (!('id' in data)) {
                console.error("Source data hasn't id field. It's required");
              }

              return (
                <StyledTableBodyRow key={data.id}>
                  {columns.map(
                    ({ key, render, width, align }: ITableColumns): ReactElement => {
                      if (key && !(key in data)) {
                        console.error(`dataSource doesn't have key of ${key} in columns`);
                      }

                      return (
                        <StyledTableBodyCell
                          key={`${data.id}_${key}`}
                          width={width}
                          align={align}
                        >
                          {isFunction(render) ? (
                            render(data[key], data, index)
                          ) : (
                            <Typography variant="body2">{data[key]}</Typography>
                          )}
                        </StyledTableBodyCell>
                      );
                    },
                  )}
                </StyledTableBodyRow>
              );
            })}
        </StyledTableBody>
      </StyledTable>
      {!dataSource?.length && (
        <Typography variant="subtitle2" my={2} textAlign="center" color={colors.primary100}>
          No Results
        </Typography>
      )}
    </StyledTableContainer>
  ) : (
    <Loader isLoading={true} />
  );
};

export { Table };
