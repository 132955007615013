import React, { ReactElement, useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Grid from '@mui/material/Grid';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import { Box } from '@mui/material';
import { ActionTypes } from 'state/actions/alert';
import { ISearchLicencesFormValues } from 'models/form';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EPerPages, ISortOptions } from 'models/table';
import { CardList } from 'components/molecules/CardList';
import { Table } from 'components/molecules/Table';
import { Pagination } from 'components/molecules/Pagination';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { clearAlertTimeout, EAlertTime } from 'utils/date';
import { Loader } from 'components/atoms/Loader';
import { useLicences } from './hooks';
import { LicencesSearchForm } from './LicencesSearchForm';
import { LicenceTableCard } from './LicenceTableCard';
import { licencesTableColumns, mapLicencesToDataSource } from './helpers';

const LicencesPage = (): ReactElement => {
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert } = useAlert();
  const { licences, getLicencesData, paginator } = useLicences();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [licencesQuery, setLicencesQuery] = useState<Partial<ISearchLicencesFormValues>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const { isDesktop } = useThemeBreakpoint();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const [isLicencesQueryLoading, setIsLicencesQueryLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    if (!shortDateFormat) {
      getDateFormat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareLicencesQuery = (query: Partial<ISearchLicencesFormValues>) => {
    Object.keys(query).forEach((key) => {
      if (!query[key as keyof ISearchLicencesFormValues]) {
        delete query[key as keyof ISearchLicencesFormValues];
      }
    });
  };

  useEffect(() => {
    if (licencesQuery !== {}) {
      setIsLicencesQueryLoading(true);
    }
  }, [licencesQuery]);

  useEffect(() => {
    (async function getLicencesDataInit() {
      prepareLicencesQuery(licencesQuery);
      setIsPaginationLoading(true);
      await getLicencesData(currentPage, perPage, licencesQuery, sort);
      setIsDataLoading(false);
      setIsLicencesQueryLoading(false);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, licencesQuery, sort]);

  useEffect(
    () => {
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_NEXT_PAGE_ALERT, EAlertTime.SHORT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleEditLicences = (id: string) =>
    navigate(ERouteLinks.EditLicence.replace(':id', id));
  const handleViewLicences = (id: string) =>
    navigate(ERouteLinks.DetailLicence.replace(':id', id));

  return (
    <>
      <Breadcrumbs items={['Licences']} />
      <StyledPageTitle variant="h4">Licences</StyledPageTitle>

      {nextPageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      <LicencesSearchForm
        onSubmit={setLicencesQuery}
        isSearchButtonDisabled={isDataLoading || isLicencesQueryLoading}
      />

      <TableTopButtons>
        <Grid container justifyContent="flex-end">
          <Grid item lg={3} xs={12}>
            <Button
              label="Add Licence"
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              onClick={() => navigate(ERouteLinks.AddLicence)}
              fullWidth={true}
              size={EButtonSizes.small}
            />
          </Grid>
        </Grid>
      </TableTopButtons>

      {isDataLoading || isLicencesQueryLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={licencesTableColumns({
                handleEdit: (id: string) => handleEditLicences(id),
                shortDateFormat,
              })}
              dataSource={mapLicencesToDataSource(licences)}
              onSort={setSort}
            />
          ) : (
            <CardList
              items={mapLicencesToDataSource(licences)}
              render={(data, index) => (
                <LicenceTableCard
                  data={data}
                  key={index}
                  shortDateFormat={shortDateFormat}
                  viewLicences={(id) => handleViewLicences(id)}
                  editLicences={(id) => handleEditLicences(id)}
                />
              )}
            />
          )}

          {paginator && (
            <Pagination
              count={paginator.totalElements}
              onChangePage={(page) => setCurrentPage(page)}
              current={currentPage}
              perPage={perPage}
              onChangePerPage={(value) => setPerPage(value)}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </>
  );
};

export { LicencesPage };
