import { EErrorPageType } from 'models/errorPage';

export const mapErrorPageTypeToLabels = (type: EErrorPageType) => {
  switch (type) {
    case EErrorPageType.AccessDenied:
      return {
        status: 403,
        title: 'Access denied',
        subtitle:
          "The page you're trying to access has restricted access. Please refer to system administrator.",
      };
    case EErrorPageType.NotFound:
      return {
        status: 404,
        title: 'Page not found',
        subtitle:
          'The link you checked may be broken or the page may have been removed or renamed.',
      };
    case EErrorPageType.ServerError:
      return {
        status: 500,
        title: 'Internal server error',
        subtitle: 'Try again later or feel free to contact us if the program persist.',
      };
    default:
      return {
        status: '',
        title: '',
        subtitle: '',
      };
  }
};
