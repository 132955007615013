import React from 'react';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import MenuLogoOpen from 'assets/images/menu-logo-open.svg';
import MenuLogoClosed from 'assets/images/menu-logo-closed.svg';
import Icon from 'components/atoms/Icon/Icon';
import { List } from '@mui/material';
import { IMenuItem, MenuItems } from 'constants/MenuItems';
import { Counter } from 'components/atoms/Counter';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuProfileItem } from 'components/molecules/MenuProfileItem';
import {
  DrawerHeader,
  DrawerImageWrapper,
  StyledDrawer,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './styles';

interface IDrawer {
  open: boolean;
  setOpen: (arg: boolean) => void;
  mobile?: boolean;
}

const MyNavLink = React.forwardRef<any, any>((props, ref) => {
  const { pathname } = useLocation();

  let isActiveItems = false;
  if (props?.items) {
    isActiveItems = props.items.some((item: string) => pathname.startsWith(item));
  }

  return (
    <NavLink
      ref={ref}
      to={props.to}
      className={({ isActive }) =>
        `${props.className} ${isActive || isActiveItems ? props.activeClassName : ''}`
      }
    >
      {props.children}
    </NavLink>
  );
});

const Drawer = ({ open, setOpen, mobile = false }: IDrawer) => (
  <StyledDrawer variant="permanent" open={open} mobile={mobile ? 1 : 0}>
    <DrawerHeader>
      {mobile ? (
        <Button
          variant={EButtonVariants.outlined}
          icon={EIconTypes.close}
          sizePX={EButtonSizesPX.px32}
          onClick={() => setOpen(!open)}
          sx={{ mt: 0.25, mr: 0.75 }}
        />
      ) : (
        <Button
          variant={EButtonVariants.contained}
          icon={open ? EIconTypes.left : EIconTypes.right}
          onClick={() => {
            setOpen(!open);
          }}
        />
      )}
    </DrawerHeader>
    <DrawerImageWrapper open={open}>
      <img
        src={open ? MenuLogoOpen : MenuLogoClosed}
        alt="Logo"
        width={open ? '140px' : '50px'}
      />
    </DrawerImageWrapper>
    <List
      sx={{ mt: open ? 0 : 8, overflowY: mobile ? 'visible' : 'auto', overflowX: 'hidden' }}
    >
      {MenuItems.map((item: IMenuItem) => (
        <StyledListItem
          component={MyNavLink}
          to={item.url}
          key={item.label}
          disablePadding
          open={open}
          activeClassName="active-list-item"
          mobile={mobile}
          items={item?.items}
        >
          <StyledListItemButton onClick={mobile ? () => setOpen(!open) : () => {}}>
            <StyledListItemIcon
              sx={{
                mr: open ? 1.5 : 'auto',
              }}
            >
              <Icon type={item.icon} />
            </StyledListItemIcon>
            <StyledListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
            {item.isNotificationItem && <Counter label={20} open={open} />}
          </StyledListItemButton>
        </StyledListItem>
      ))}
    </List>
    <MenuProfileItem open={open} mobile={mobile} />
  </StyledDrawer>
);

export { Drawer };
