import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { ReCaptchaProvider } from 'state/contexts/recaptcha';
import App from './App';
import reportWebVitals from './reportWebVitals';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (...rest) {
  const event = new Event('itemInserted');
  document.dispatchEvent(event);
  originalSetItem.apply(this, rest);
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function (...rest) {
  const event = new Event('itemRemoved');
  document.dispatchEvent(event);
  originalRemoveItem.apply(this, rest);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ReCaptchaProvider>
        <App />
      </ReCaptchaProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
