import { IPaginator } from 'models/paginator';
import { useState } from 'react';
import { EPerPages } from 'models/table';
import { ITranslation } from 'models/translation';
import { ISystemKeysFormValues, ITranslationFormValues } from 'models/form';
import {
  updateSystemKeysFile,
  getTranslations,
  getTranslation,
  createTranslation,
  updateTranslation,
  getSystemKeysFile,
  getDefaultTranslation,
} from 'requests/translation';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';

export const useTranslations = () => {
  const [translations, setTranslations] = useState<ITranslation[]>([]);
  const [translation, setTranslation] = useState<ITranslation | null>();
  const [defaultTranslation, setDefaultTranslation] = useState<ITranslation | null>();
  const [systemKeysFile, setSystemKeysFile] = useState<ITranslation | null>();
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const { setAlert } = useAlert();

  const handleSystemKeysFileEdit = async (id: string, data: ISystemKeysFormValues) => {
    const response = await updateSystemKeysFile(id, data);
    if (response) {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Translations have been updated successfully.',
      });
    }
    return response;
  };

  const getTranslationsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
  ) => {
    const translationsData = await getTranslations(currentPage, perPage);

    if (translationsData) {
      const { content, ...paginatorData } = translationsData;
      setTranslations(content.filter((item: ITranslation) => !item.defaultLanguage));
      setPaginator(paginatorData);
    } else {
      setTranslations([]);
      setPaginator(null);
    }
  };

  const getSystemKeysFileData = async () => {
    const data = await getSystemKeysFile();
    if (data) {
      setSystemKeysFile(data);
    } else {
      setSystemKeysFile(null);
    }
  };

  const getTranslationData = async (id: string) => {
    const translationsData = await getTranslation(id);
    if (translationsData) {
      setTranslation(translationsData);
    } else {
      setTranslation(null);
    }
  };

  const getDefaultTranslationData = async () => {
    const translationData = await getDefaultTranslation();
    setDefaultTranslation(translationData);
  };

  const handleTranslationEdit = async (id: string, data: ITranslationFormValues) => {
    const response = await updateTranslation(id, data);
    if (response) {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Translations have been updated successfully.',
      });
    }
    return response;
  };

  const handleTranslationCreate = async (data: ITranslationFormValues) => {
    const response = await createTranslation(data);
    if (response && response.status === 200) {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Translation has been created successfully.',
      });
    } else if (response && response.status === 400) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'Something went wrong.',
        variant: EAlertVariants.error,
      });
    }
    return response;
  };

  return {
    translations,
    translation,
    systemKeysFile,
    defaultTranslation,
    paginator,
    handleSystemKeysFileEdit,
    getSystemKeysFileData,
    getTranslationsData,
    getTranslationData,
    handleTranslationEdit,
    handleTranslationCreate,
    getDefaultTranslationData,
  };
};
