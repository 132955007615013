import { EPerPages } from 'models/table';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ITranslation, ITranslationsResponse } from 'models/translation';
import { ISystemKeysFormValues, ITranslationFormValues } from 'models/form';
import { ApiEndpoints } from 'models/apiEndpoints';
import { queryString, queryStringWithSort } from 'helpers/request';
import {
  RequestHeaderKeyTypes,
  RequestHeaderValueTypes,
} from '../constants/RequestHeaderTypes';

const updateSystemKeysFile = async (uniqueId: string, data: ISystemKeysFormValues) => {
  const { translationsFile } = data;
  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const fileFieldName = 'translationsFile';
  const formData = new FormData();
  formData.append(fileFieldName, translationsFile);
  const [res] = await Promise.all([
    apiClient.put(ApiEndpoints.UPDATE_TRANSLATION_FILE(uniqueId), formData, config),
  ]);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};
const getTranslations = async (page: number, size: EPerPages) => {
  const query = queryStringWithSort({ page: page - 1, size }, null);
  const { data } = await apiClient.get<TResponse<ITranslationsResponse>>(
    `${ApiEndpoints.GET_TRANSLATIONS_LANGUAGES()}?${query}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getSystemKeysFile = async (): Promise<ITranslation | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_TRANSLATION_DEFAULT());
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getTranslation = async (id: string): Promise<ITranslation | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_TRANSLATION(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getDefaultTranslation = async (): Promise<ITranslation | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_TRANSLATION_DEFAULT());
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createTranslation = async (data: ITranslationFormValues) => {
  const { translationsFile, ...request } = data;
  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const formData = new FormData();
  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (translationsFile) {
    formData.append('translationsFile', translationsFile);
  }

  const res = await apiClient.post(ApiEndpoints.CREATE_TRANSLATION(), formData, config);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const updateTranslation = async (uniqueId: string, data: ITranslationFormValues) => {
  const { translationsFile, ...request } = data;
  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const formData = new FormData();
  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (translationsFile) {
    formData.append('translationsFile', translationsFile);
  } else {
    formData.append('translationsFile', new Blob());
  }

  const res = await apiClient.put(ApiEndpoints.UPDATE_TRANSLATION(uniqueId), formData, config);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const prepareFileName = (fileName?: string) => {
  if (!fileName) return 'template.csv';
  const extensionRegex = /(?:\.([^.]+))?$/.exec(fileName);
  if (extensionRegex && !!extensionRegex[1]) return fileName;
  return `${fileName}.csv`;
};

const downloadFile = async (
  id?: string | undefined,
  fileName?: string | undefined,
  isTemplate?: boolean | undefined,
): Promise<Blob | null> => {
  const query = queryString({ isTemplate: isTemplate || false });
  const urlToGet = id
    ? `${ApiEndpoints.DOWNLOAD_TRANSLATION_FILE(id)}?${query}`
    : `${ApiEndpoints.DOWNLOAD_DEFAULT_TRANSLATION_FILE()}`;
  const { data } = await apiClient.get(urlToGet, {
    responseType: 'arraybuffer',
    headers: {
      [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
    },
  });
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', prepareFileName(fileName));
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  updateSystemKeysFile,
  getTranslations,
  getTranslation,
  createTranslation,
  updateTranslation,
  downloadFile,
  getSystemKeysFile,
  getDefaultTranslation,
};
