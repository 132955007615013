import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { EGlobalPropertyTypes, IGlobalProperty } from 'models/globalProperty';

const getGlobalProperties = async (): Promise<IGlobalProperty[] | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_GLOBAL_PROPERTIES());

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getGlobalPropertiesByKey = async (
  key: EGlobalPropertyTypes,
): Promise<IGlobalProperty | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_GLOBAL_PROPERTIES_BY_KEY(key));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { getGlobalProperties, getGlobalPropertiesByKey };
