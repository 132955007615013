import React, { useState, useEffect } from 'react';
import { initialLicencesSearchFormValues, ISearchLicencesFormValues } from 'models/form';
import { useForm } from 'react-hook-form';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchLicencesFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { prepareFilters } from 'helpers/filters';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Grid from '@mui/material/Grid';
import { StyledAdvancedSearch } from 'theme/styles';
import { useLicences } from '../hooks';

interface ILicencesSearchForm {
  onSubmit: (arg: ISearchLicencesFormValues) => void;
  isSearchButtonDisabled?: boolean;
}

const LicencesSearchForm = ({
  onSubmit,
  isSearchButtonDisabled = false,
}: ILicencesSearchForm) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchLicencesFormValues>({
    defaultValues: initialLicencesSearchFormValues,
  });
  const [values, setValues] = useState<ISearchLicencesFormValues>(
    initialLicencesSearchFormValues,
  );

  const { loadDictionaries, country } = useLicences();

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialLicencesSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchLicencesFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledAdvancedSearch container justifyContent="space-between" spacing={2}>
          <Grid item lg={3} sm={6} xs={12}>
            <FormInput
              name="licenceId"
              label={ESearchLicencesFormLabelsTypes.licenceId}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <FormInput
              name="purchaserName"
              label={ESearchLicencesFormLabelsTypes.purchaserName}
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(country)}
              name="countryUniqueId"
              canBeEmpty={true}
              label={ESearchLicencesFormLabelsTypes.countryUniqueId}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} sx={{ alignSelf: 'center' }}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label="Search"
              disabled={isSearchButtonDisabled}
              type="submit"
              fullWidth={true}
              sx={{ mt: '6px' }}
            />
          </Grid>
        </StyledAdvancedSearch>
      </form>
      <SelectedFilters
        values={values}
        labels={ESearchLicencesFormLabelsTypes}
        handleDelete={handleResetField}
        dictionaries={{
          countryUniqueId: prepareFilters(country, 'uniqueId', 'name'),
        }}
        noPreset
      />
    </SearchWrapper>
  );
};

export { LicencesSearchForm };
