import React from 'react';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { StyledIconWrapper, StyledWrapper } from './styles';

export enum EAlertVariants {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export interface IAlert {
  text: string;
  variant?: EAlertVariants;
}

export const Alert = ({ text, variant = EAlertVariants.success }: IAlert) => {
  let icon: EIconTypes;
  let iconBackgroundColor: string;
  let backgroundColor: string;
  let textColor: string;
  switch (variant) {
    case EAlertVariants.success:
      textColor = colors.success600;
      backgroundColor = colors.success50;
      iconBackgroundColor = colors.success600;
      icon = EIconTypes.checkmark;
      break;
    case EAlertVariants.warning:
      textColor = colors.warning700;
      backgroundColor = colors.warning50;
      iconBackgroundColor = colors.warning700;
      icon = EIconTypes.info;
      break;
    case EAlertVariants.error:
      textColor = colors.error700;
      backgroundColor = colors.error50;
      iconBackgroundColor = colors.error700;
      icon = EIconTypes.info;
      break;
    default:
      textColor = colors.success600;
      backgroundColor = colors.success50;
      iconBackgroundColor = colors.success50;
      icon = EIconTypes.info;
  }
  return (
    <StyledWrapper backgroundColor={backgroundColor}>
      <StyledIconWrapper iconBackgroundColor={iconBackgroundColor}>
        <Icon type={icon} color={colors.white} fontSize={16} />
      </StyledIconWrapper>
      <Typography variant="caption" color={textColor} ml={2}>
        {text}
      </Typography>
    </StyledWrapper>
  );
};
