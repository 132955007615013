import styled from '@emotion/styled';
import { Select } from '@mui/material';
import { colors } from 'theme/colors';
import FormControl from '@mui/material/FormControl';

export const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

export const StyledSelect = styled(Select)((props: any) => ({
  '#select': {
    padding: '8px 16px',
    fontSize: 14,
  },
  '& .MuiFormControl-root ': {
    width: '100%',
  },
  '.MuiSelect-select': {
    padding: '6px 12px',
    border: 0,
  },
  '.MuiMenuItem-root': {
    width: '100%',
    fontSize: 14,
    boxSizing: 'border-box',

    '& .MuiSelect-select': {
      height: 32,
      padding: 0,
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputBase-input': {
    border: 0,
    ...(props.disabled
      ? { backgroundColor: colors.gray100 }
      : { backgroundColor: colors.white }),
  },
  '& .MuiInputBase-root': {
    height: 32,
  },
  '#daysQuantity': {
    boxSizing: 'border-box',
    padding: '6px 12px',
    width: 65,
    height: 32,
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export const StyledInnerWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
  height: 32px;

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 32px;
    padding: 0;
    background-color: ${colors.white};
  }

  & .MuiSelect-select {
    height: 32px;
    box-sizing: border-box;
    background-color: ${colors.white};
  }
`;
