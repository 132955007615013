import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { ILicence } from 'models/licence';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { formatDate } from 'utils/date';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

export interface ILanguages {
  label: string;
  value: string;
  defaultLanguage: boolean;
}

export const mapLicencesToDataSource = (roles: ILicence[]): ITableDataSource[] =>
  roles.map((licences) => ({
    id: licences.uniqueId,
    licenceId: licences.licenceId,
    licenceType: licences.licenceType,
    purchaserName: licences.purchaserName,
    countryUniqueId: licences.countryUniqueId,
    country: licences.country,
    numberOfUsers: licences.numberOfUsers,
    expiryDate: licences.expiryDate,
  }));

interface ILicencesTableColumns {
  handleEdit: (arg: string) => void;
  shortDateFormat: string;
}

export const licencesTableColumns = ({
  handleEdit,
  shortDateFormat,
}: ILicencesTableColumns): ITableColumns[] => [
  {
    id: '4eaea4ee-d36f-400b-895b-231c81f091cf',
    key: 'licenceId',
    isSortable: false,
    title: 'Licence ID',
  },
  {
    id: '4eaea4ee-d36f-440b-895b-231c81f091cf',
    key: 'licenceType',
    isSortable: false,
    title: 'Licence Type',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        {props.licenceType.ONLINE && (
          <Chips text={props.licenceType.ONLINE} variant={EChipsVariants.warning} />
        )}
        {props.licenceType.OFFLINE && (
          <Chips text={props.licenceType.OFFLINE} variant={EChipsVariants.primary} />
        )}
      </>
    ),
  },
  {
    id: '4eaea4ee-d36f-420b-895b-231c81f091cf',
    key: 'purchaserName',
    isSortable: false,
    title: 'Purchaser',
  },
  {
    id: '4eaea4ee-d36f-430b-895b-231c81f091cf',
    key: 'country',
    isSortable: false,
    title: 'Country',
  },
  {
    id: '4eaea4ee-d36f-430b-895b-231c81f091cg',
    key: 'numberOfUsers',
    isSortable: false,
    title: 'Number Of Users',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) =>
      props.numberOfUsers || <Chips text="Unlimited" variant={EChipsVariants.success100} />,
  },
  {
    id: '4eaea4ee-d36f-460b-895b-231c81f091cf',
    key: 'expiryDate',
    isSortable: false,
    title: 'Expiry Date',
    align: 'center',
    width: '180',
    isCenterTableHead: true,
    render: (_data, props) =>
      props.expiryDate ? (
        formatDate(props.expiryDate, shortDateFormat)
      ) : (
        <Chips text="perpetual" variant={EChipsVariants.success100} />
      ),
  },
  {
    id: '4eaea4ee-d36f-500b-895b-231c81f091cf',
    key: '',
    title: 'Action',
    isSortable: false,
    isCenterTableHead: true,
    width: '110px',
    align: 'center',
    render: (_data, props) => (
      <>
        <Link to={`${ERouteLinks.DetailLicence.replace(':id', props.id)}`}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
        />
      </>
    ),
  },
];
