import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledPasswordRulesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    position: relative;
  }

  li:before {
    content: '';
    position: absolute;
    top: 50%;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${colors.gray900};
  }

  li .MuiTypography-root {
    margin-left: 20px;
  }
`;
