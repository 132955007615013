export const prepareFilters = (data: any, idKey: string, labelKey: string) => {
  if (!data) {
    return data;
  }

  const result: any = {};

  data.forEach((item: any) => {
    if (
      Object.prototype.hasOwnProperty.call(item, idKey) &&
      Object.prototype.hasOwnProperty.call(item, labelKey)
    ) {
      result[item[idKey]] = item[labelKey];
    }
  });

  return result;
};
