import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/dictionary';
import { DictionaryContext } from 'models/dictionaryContext';
import { DictionaryActionTypes } from 'state/actions/dictionary';
import { getDictionary, getSpecificDictionary } from 'requests/dictionary';
import { EDictionaryTypes, ESpecificDictionaryTypes } from 'models/dictionary';

export const DictionaryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const getGender = async () => {
    const genderData = await getDictionary(EDictionaryTypes.Gender);
    dispatch({ type: DictionaryActionTypes.SET_GENDER, payload: genderData });
  };

  const getOrganizationType = async () => {
    const organizationTypes = await getDictionary(EDictionaryTypes.OrganizationType);
    dispatch({
      type: DictionaryActionTypes.SET_ORGANIZATION_TYPE,
      payload: organizationTypes,
    });
  };

  const getLegalVisibility = async () => {
    const legalVisibilityData = await getDictionary(EDictionaryTypes.LegalVisibility);
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_VISIBILITY,
      payload: legalVisibilityData,
    });
  };

  const getMake = async () => {
    const makeData = await getSpecificDictionary(ESpecificDictionaryTypes.Make);
    dispatch({
      type: DictionaryActionTypes.SET_MAKE,
      payload: makeData,
    });
  };

  const getModel = async () => {
    const modelData = await getSpecificDictionary(ESpecificDictionaryTypes.Model);
    dispatch({
      type: DictionaryActionTypes.SET_MODEL,
      payload: modelData,
    });
  };

  const getCaliber = async () => {
    const caliberData = await getSpecificDictionary(ESpecificDictionaryTypes.Caliber);
    dispatch({
      type: DictionaryActionTypes.SET_CALIBER,
      payload: caliberData,
    });
  };

  const getState = async () => {
    const stateData = await getDictionary(EDictionaryTypes.State);
    dispatch({
      type: DictionaryActionTypes.SET_STATE,
      payload: stateData,
    });
  };

  const getRegion = async () => {
    const regionData = await getDictionary(EDictionaryTypes.Region);
    dispatch({
      type: DictionaryActionTypes.SET_REGION,
      payload: regionData,
    });
  };

  const getCountry = async () => {
    const countryData = await getDictionary(EDictionaryTypes.Country);
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY,
      payload: countryData,
    });
  };

  const getPhoneCode = async () => {
    const phoneCodeData = await getDictionary(EDictionaryTypes.PhoneCode);
    dispatch({
      type: DictionaryActionTypes.SET_PHONE_CODE,
      payload: phoneCodeData,
    });
  };

  const getAddressType = async () => {
    const addressTypeData = await getDictionary(EDictionaryTypes.AddressType);
    dispatch({
      type: DictionaryActionTypes.SET_ADDRESS_TYPE,
      payload: addressTypeData,
    });
  };

  const getLegality = async () => {
    const legalityData = await getDictionary(EDictionaryTypes.Legality);
    dispatch({ type: DictionaryActionTypes.SET_LEGALITY, payload: legalityData });
  };

  const getManufacturer = async () => {
    const manufacturerData = await getSpecificDictionary(
      ESpecificDictionaryTypes.Manufacturer,
    );
    dispatch({
      type: DictionaryActionTypes.SET_MANUFACTURER,
      payload: manufacturerData,
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      getGender,
      getOrganizationType,
      getLegalVisibility,
      getMake,
      getModel,
      getCaliber,
      getState,
      getRegion,
      getCountry,
      getPhoneCode,
      getAddressType,
      getLegality,
      getManufacturer,
    }),
    [state],
  );

  return <DictionaryContext.Provider value={value}>{children}</DictionaryContext.Provider>;
};
