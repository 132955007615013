import React, { useEffect } from 'react';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useAuth } from 'models/authContext';
import {
  StyledLogoutButton,
  StyledMenuProfileItem,
} from 'components/molecules/MenuProfileItem/styles';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

interface IMenuProfileItem {
  open: boolean;
  mobile: boolean;
}

const MenuProfileItem = ({ open, mobile }: IMenuProfileItem) => {
  const navigate = useNavigate();
  const { logOut, isLogOutLoading, user, getUser } = useAuth();

  useEffect(() => {
    if (user === null) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <StyledMenuProfileItem open={open} mobile={mobile}>
        <div>
          <p>{`${user?.firstName} ${user?.lastName}`}</p>
          <p>{user?.email}</p>
        </div>
        <div>
          <Button
            variant={EButtonVariants.contained}
            icon={EIconTypes.settings}
            sizePX={open ? EButtonSizesPX.px24 : EButtonSizesPX.px48}
            onClick={() => navigate(ERouteLinks.Account)}
          />
        </div>
      </StyledMenuProfileItem>

      <StyledLogoutButton
        open={open}
        mobile={mobile}
        label="Log out"
        variant={EButtonVariants.contained}
        icon={EIconTypes.logout}
        onClick={() =>
          logOut(() => {
            navigate(ERouteLinks.Auth);
          })
        }
        isLoading={isLogOutLoading}
      />
    </>
  );
};
export { MenuProfileItem };
