import { IReducerAction } from 'models/context';
import { EActionTypes } from 'state/actions/globalProperty';

interface IState {
  shortDateFormat: string;
  midDateFormat: string;
  longDateFormat: string;
}

export const initialState: IState = {
  shortDateFormat: 'dd/MM/yyyy',
  midDateFormat: 'dd/MM/yyyy HH:mm',
  longDateFormat: 'dd/MM/yyyy HH:mm:ss',
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case EActionTypes.GET_DATE_FORMAT: {
      if (payload) {
        return {
          ...state,
          shortDateFormat: payload,
          midDateFormat: `${payload} HH:mm`,
          longDateFormat: `${payload} HH:mm:ss`,
        };
      }
      return state;
    }
    default:
      return state;
  }
};
