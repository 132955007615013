import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { colors } from 'theme/colors';

const StyledTableContainer = styled(TableContainer)``;

const StyledTable = styled(Table)``;

const StyledTableHead = styled(TableHead)``;

const StyledTableHeadRow = styled(TableRow)`
  height: 32px;
`;

const StyledTableHeadCell = styled(TableCell)`
  padding: 0;
  border: none;

  & > div {
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    background-color: ${colors.primary50};
    color: ${colors.primary900};
    font-weight: 600;
    letter-spacing: 0.02em;
    padding: 6px 0 6px 12px;
    margin-right: 1px;

    & > h6 {
      color: ${colors.primary900};
    }
  }

  &:first-of-type > div {
    border-radius: 4px 0 0 0;
  }

  &:last-child > div {
    border-radius: 0 4px 0 0;
  }
`;
const StyledTableBody = styled(TableBody)``;

const StyledTableBodyRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${colors.primary15};
  }

  &:hover {
    background-color: ${colors.error15};
  }
`;

const StyledTableBodyCell = styled(TableCell)`
  color: ${colors.gray900};
  height: 32px;
  padding: 6px 12px;
  border: none;
`;

export {
  StyledTableContainer,
  StyledTable,
  StyledTableHead,
  StyledTableHeadRow,
  StyledTableHeadCell,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableBodyCell,
};
