import React, { useEffect, useState } from 'react';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { FormInput } from 'components/molecules/FormInput';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import useUserFormSchema from 'pages/UsersPage/schemaValidation';
import { useForm } from 'react-hook-form';
import { useUsers } from 'pages/UsersPage/hooks';
import { useDictionary } from 'models/dictionaryContext';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import { StyledForm } from 'theme/styles';

export interface IUserForm {
  initialFormValues?: IUserFormValues | null;
  onCancel?: () => void;
  onSubmit: (arg: IUserFormValues) => void;
  isEdit?: boolean;
}

const UserForm = ({ initialFormValues, onCancel, onSubmit, isEdit = false }: IUserForm) => {
  const { loadDictionaries } = useUsers();
  const { phoneCode } = useDictionary();
  const { schema } = useUserFormSchema();
  const { userAlert, clearAlert } = useAlert();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IUserFormValues>({
    defaultValues: initialFormValues || initialUserFormValues,
    resolver: yupResolver(schema),
  });
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function init() {
      await loadDictionaries();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(initialFormValues || initialUserFormValues);
  }, [reset, initialFormValues]);

  useEffect(
    () => {
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitForm = async (data: IUserFormValues) => {
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({ ...initialFormValues, ...data });
    } else {
      await onSubmit(data);
    }
    setIsSubmitLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <StyledForm container item spacing={2} lg={8}>
        <Grid item xs={12} md={12} lg={12}>
          <FormInput
            name="email"
            label="Email*"
            control={control}
            disabled={isEdit}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormInput
            name="firstName"
            label="First Name*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormInput
            name="lastName"
            label="Last Name*"
            control={control}
            errors={errors}
            setValue={setValue}
            isClearable
          />
        </Grid>

        <Grid item xs={12} lg={12} container columnSpacing={2}>
          <Grid item xs={6} md={6} lg={4}>
            {phoneCode && (
              <FormSelect
                name="phoneNumberCodeDictionaryUniqueId"
                options={mapDictionaryToOptionProp(phoneCode)}
                label="Phone Prefix*"
                control={control}
                canBeEmpty
                setValue={setValue}
                errors={errors}
              />
            )}
          </Grid>
          <Grid item xs={6} md={6} lg={8}>
            <FormInput
              name="phoneNumberValue"
              label="Phone Number*"
              control={control}
              errors={errors}
              setValue={setValue}
              isClearable
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={6}>
            <Button
              type="submit"
              fullWidth
              label="Save"
              size={EButtonSizes.small}
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={6}>
              <Button
                fullWidth
                label="Cancel"
                size={EButtonSizes.small}
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>

        <Grid item mt={2} xs={12}>
          <Typography variant="caption">*Mandatory field</Typography>
        </Grid>
        <Grid item mt={3} xs={12}>
          {userAlert && <Alert text={userAlert.text} variant={userAlert.variant} />}
        </Grid>
      </StyledForm>
    </form>
  );
};

export { UserForm };
