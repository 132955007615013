import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { formatDate } from 'utils/date';

interface ILicenceTableCard {
  data: ITableDataSource;
  viewLicences: (id: string) => void;
  editLicences: (id: string) => void;
  shortDateFormat: string;
}

const LicenceTableCard = ({
  data,
  viewLicences,
  editLicences,
  shortDateFormat,
}: ILicenceTableCard) => (
  <Grid
    container
    sx={{
      border: `1px solid ${colors.gray200}`,
      borderRadius: '4px',
      overflow: 'hidden',
      mt: 1,
      backgroundColor: colors.gray100,
    }}
  >
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Licence ID
      </Typography>
      <Typography variant="body2" sx={{ wordBreak: 'break-all', 'overflow-wrap': 'anywhere' }}>
        {data.licenceId}
      </Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Licence Type
      </Typography>
      {data.licenceType && data.licenceType.ONLINE && (
        <Chips text={data.licenceType.ONLINE} variant={EChipsVariants.warning} />
      )}
      {data.licenceType && data.licenceType.OFFLINE && (
        <Chips text={data.licenceType.OFFLINE} variant={EChipsVariants.primary} />
      )}
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Purchaser
      </Typography>
      <Typography variant="body2">{data.purchaserName}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Country
      </Typography>
      <Typography variant="body2">{data.country}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Number of Users
      </Typography>
      <Typography variant="body2">
        {data.numberOfUsers || <Chips text="Unlimited" variant={EChipsVariants.success100} />}
      </Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Expiry Date
      </Typography>
      {data.expiryDate ? (
        <Typography variant="body2">{formatDate(data.expiryDate, shortDateFormat)}</Typography>
      ) : (
        <Chips text="Perpetual" variant={EChipsVariants.success100} />
      )}
    </Grid>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        m: {
          xs: 2,
          lg: 2,
        },
      }}
      spacing={2}
    >
      <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
          Actions
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={9} justifyContent="flex-end" columnGap={3}>
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Button
            label="Edit"
            variant={EButtonVariants.outlined}
            onClick={() => editLicences(data.id as string)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Button
            label="View"
            variant={EButtonVariants.outlined}
            onClick={() => viewLicences(data.id as string)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export { LicenceTableCard };
