import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

export const schema = yup.object({
  newPassword: yup
    .string()
    .required('This field is required.')
    .min(
      8,
      'Password must contain from 8 to 64 characters, at least one upper and special character.',
    )
    .max(
      64,
      'Password must contain from 8 to 64 characters, at least one upper and special character.',
    )
    .matches(
      new RegExp(ERegExpTypes.password, 'g'),
      'Password must contain from 8 to 64 characters, at least one upper and special character.',
    ),

  newPasswordRepeat: yup
    .string()
    .required('This field is required.')
    .oneOf([yup.ref('newPassword'), null], 'Passwords are not the same.'),
});
