import styled from '@emotion/styled';
import MuiDrawer from '@mui/material/Drawer';
import { css, Theme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { colors } from 'theme/colors';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { NavLinkProps } from 'react-router-dom';

interface IStyledListItem {
  open: boolean;
  component?: ForwardRefExoticComponent<NavLinkProps & RefAttributes<HTMLAnchorElement>>;
  to?: string;
  activeClassName?: string;
  mobile?: boolean;
  items?: string[];
}

interface IOpen {
  open: boolean;
}

interface IStyledDrawer extends IOpen {
  mobile: number;
}

const openedMixin = css`
  width: 220px;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  transition-duration: 0.225s;
`;

const closedMixin = css`
  width: 68px;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  transition-duration: 0.195s;
`;

const StyledDrawer = styled(MuiDrawer)<IStyledDrawer>`
  overflow-x: hidden;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  & .MuiDrawer-paper {
    padding-bottom: 36px;
  }

  ${(props: IStyledDrawer) =>
    props.open &&
    css`
      ${openedMixin};

      & .MuiDrawer-paper {
        ${openedMixin};
      }
    `}

  ${(props: IStyledDrawer) =>
    !props.open &&
    css`
      ${closedMixin};

      & .MuiDrawer-paper {
        ${closedMixin};
      }
    `}
    ${(props: IStyledDrawer) =>
    props.mobile &&
    css`
      width: 100%;

      & .MuiDrawer-paper {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        border-right: none;
      }
    `}
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`;

const DrawerImageWrapper = styled.div<IOpen>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  ${(props: IOpen) =>
    !props.open &&
    css`
      padding: 30px 0 0 0;
      margin-bottom: 0px;

      & > span {
        display: none;
      }
    `}
  & > span {
    margin-top: -25px;
    color: ${colors.primary500};
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
`;

const StyledListItem = styled(ListItem)<IStyledListItem>`
  display: flex;
  width: 200px;
  margin: 0 auto 4px;
  justify-content: center;
  align-items: center;

  ${(props: IStyledListItem) =>
    !props.open &&
    css`
      width: 48px;
    `}

  ${(props: IStyledListItem) =>
    props.mobile &&
    css`
      background-color: ${colors.primary15};
    `}

    ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('sm')} {
      width: 95%;
      };
      `};
`;

const StyledListItemButton = styled(ListItemButton)`
  min-height: 48px;
  height: 48px;
  border-radius: 4px;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  width: 48px;

  .active-list-item & {
    background-color: ${colors.primary900};

    & .MuiTypography-root {
      color: ${colors.white} !important;
    }

    & .MuiSvgIcon-root {
      color: ${colors.white};
    }

    &:hover {
      background-color: ${colors.primary900};
    }
  }

  &:hover {
    background-color: ${colors.primary25};
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 0;
  justify-content: center;
  color: ${colors.primary900};
`;

const StyledListItemText = styled(ListItemText)`
  & span {
    font-family: 'NotoSansRegular', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
  }
`;

export {
  StyledDrawer,
  DrawerHeader,
  DrawerImageWrapper,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
};
