import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropzoneWrapper } from 'components/atoms/UploadDropzoneField/styles';

interface IUploadDropzoneField {
  onUpload: (...args: any) => void;
  shouldClearFiles?: boolean;
  setFilesCleared?: (arg0: boolean) => void;
}

const UploadDropzoneField = ({
  onUpload,
  shouldClearFiles = false,
  setFilesCleared,
}: IUploadDropzoneField) => {
  const [files, setFiles] = useState<any>([]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setFiles([...acceptedFiles]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    maxSize: 3 * 1024 * 1024,
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop,
  });

  useEffect(() => {
    if (shouldClearFiles) {
      setFiles([]);
      if (setFilesCleared) {
        setTimeout(() => {
          setFilesCleared(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearFiles]);

  useEffect(() => {
    if (files?.length) {
      onUpload(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  // @ts-ignore
  return (
    <section className="container">
      <DropzoneWrapper {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <p>
          Drag & drop CSV file here <br />
          or click to browse
        </p>
      </DropzoneWrapper>
    </section>
  );
};

export default UploadDropzoneField;
