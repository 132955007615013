import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React, { PropsWithChildren } from 'react';

const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeVuUUpAAAAALkjFqVKYmiFSbzfnhBAjXuuAjnQ';

export const ReCaptchaProvider = ({ children }: PropsWithChildren<{}>) => (
  <GoogleReCaptchaProvider language="en" useEnterprise reCaptchaKey={RECAPTCHA_SITE_KEY}>
    {children}
  </GoogleReCaptchaProvider>
);
