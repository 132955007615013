import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Drawer } from 'components/organisms/Drawer';
import { Footer } from 'components/atoms/Footer';
import { FC_MANAGER_AUTH_ACCESS_TOKEN } from 'requests/auth';
import { useLocation } from 'react-router-dom';
import { config } from 'pages/config';
import { IPageBuilderConfig } from 'models/route';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { HamburgerMenu } from 'components/molecules/HamburgerMenu';
import { useAuth } from 'models/authContext';
import { PageWrapper } from './styles';

const Layout = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const { isDesktop } = useThemeBreakpoint();

  const [open, setOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const location = useLocation();

  const { getUser, user } = useAuth();

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN)]);

  const handleDrawerToggle = (value: boolean) => {
    setOpen(value);
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (user === null) {
        await getUser();
      }
    };
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  document.addEventListener(
    'itemInserted',
    () => {
      if (localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN)) {
        setIsAuthenticated(true);
      }
    },
    false,
  );

  document.addEventListener(
    'itemRemoved',
    () => {
      if (localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN) && user) {
        setIsAuthenticated(false);
      }
    },
    false,
  );

  const isRouteNotProtected =
    config.find((route: IPageBuilderConfig) => route.route === location.pathname)
      ?.isProtected === false;

  return (
    <>
      {isAuthenticated && !isRouteNotProtected && !isDesktop && <HamburgerMenu />}
      <Box sx={{ display: 'flex' }}>
        {isAuthenticated && !isRouteNotProtected ? (
          <>
            {isDesktop && <Drawer open={open} setOpen={handleDrawerToggle} />}
            <Box component="main" sx={{ flexGrow: 1 }}>
              <PageWrapper>{children}</PageWrapper>
              <Footer />
            </Box>
          </>
        ) : (
          <Box component="main" sx={{ flexGrow: 1 }}>
            {children}
          </Box>
        )}
      </Box>
    </>
  );
};

export { Layout };
