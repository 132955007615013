export enum EButtonVariants {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum EButtonSizes {
  medium = 'medium',
  small = 'small',
}

export enum EButtonSizesPX {
  px48 = '48px',
  px40 = '40px',
  px32 = '32px',
  px24 = '24px',
}
