import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { UserSearchForm } from 'pages/UsersPage/UserSearchForm';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Grid from '@mui/material/Grid';
import { useUsers } from 'pages/UsersPage/hooks';
import { EPerPages, ISortOptions } from 'models/table';
import { ISearchUserFormValues } from 'models/form';
import { Pagination } from 'components/molecules/Pagination';
import { mapUsersToDataSource, usersTableColumns } from 'pages/UsersPage/helpers';
import { Table } from 'components/molecules/Table';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import { UserTableCard } from 'pages/UsersPage/UserTableCard';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { EUserStatus } from 'models/user';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import { Box } from '@mui/material';
import { useAuth } from 'models/authContext';
import { Loader } from 'components/atoms/Loader';

const UsersPage = (): ReactElement => {
  const navigate = useNavigate();
  const { nextPageAlert, clearAlert } = useAlert();
  const { user } = useAuth();
  const { users, setUsers, getUsersData, paginator, setUserBlocked } = useUsers();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [userQuery, setUserQuery] = useState<Partial<ISearchUserFormValues>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const { isDesktop } = useThemeBreakpoint();
  const [isUserQueryLoading, setIsUserQueryLoading] = useState<boolean>(false);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const previousSortRef = useRef(sort);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (userQuery !== {}) {
      setIsUserQueryLoading(true);
    }
  }, [userQuery]);

  useEffect(() => {
    (async function getUsersDataInit() {
      if (sort !== previousSortRef.current) {
        setIsSortLoaded(false);
      }
      setIsPaginationLoading(true);
      await getUsersData(currentPage, perPage, userQuery, sort);
      setIsDataLoading(false);
      setIsUserQueryLoading(false);
      setIsPaginationLoading(false);
      setIsSortLoaded(true);
      previousSortRef.current = sort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, userQuery, sort]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isSortLoaded]);

  const handleActivateUserToggle = (id: string, isActivate = true) =>
    navigate(
      ERouteLinks.ChangeStatusUser.replace(':id', id).replace(
        ':statusAction',
        isActivate ? EUserStatus.Activate : EUserStatus.Deactivate,
      ),
    );

  const unbanUser = async (id: string) => {
    const result = await setUserBlocked(id);
    if (result) {
      setUsers((prevState) =>
        prevState.map((item) => {
          if (item.uniqueId === id) {
            item.blocked = false;
          }
          return item;
        }),
      );
    }
  };

  const handleEditUser = (id: string) => navigate(ERouteLinks.EditUser.replace(':id', id));

  return (
    <>
      <Breadcrumbs items={['Users']} />
      <StyledPageTitle variant="h4">Users</StyledPageTitle>

      {nextPageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      <UserSearchForm
        onSubmit={setUserQuery}
        isSearchButtonDisabled={isDataLoading || isUserQueryLoading}
      />

      <TableTopButtons>
        <Grid container justifyContent="flex-end" columns={10}>
          <Grid item lg={2} sm={5} xs={10}>
            <Button
              label="Add User"
              size={EButtonSizes.small}
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              onClick={() => navigate(ERouteLinks.AddUser)}
              fullWidth={true}
            />
          </Grid>
        </Grid>
      </TableTopButtons>

      {isDataLoading || isUserQueryLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {user?.userId && isDesktop ? (
            <Table
              columns={usersTableColumns({
                handleEdit: (id: string) => handleEditUser(id),
                handleActivate: (id: string) => handleActivateUserToggle(id, true),
                handleDeactivate: (id: string) => handleActivateUserToggle(id, false),
                handleUnban: (id: string) => unbanUser(id),
                loggedUserId: user!!.userId!!,
              })}
              dataSource={mapUsersToDataSource(users)}
              onSort={(it) => {
                setSort(it);
                scrollPosition.current = window.scrollY;
              }}
              isSortLoaded={isSortLoaded}
            />
          ) : (
            <CardList
              items={mapUsersToDataSource(users)}
              render={(data, index) => (
                <UserTableCard
                  data={data}
                  key={index}
                  unbanUser={unbanUser}
                  activateUser={(id, isActivate) => handleActivateUserToggle(id, isActivate)}
                  editUser={(id) => handleEditUser(id)}
                  loggedUserId={user!!.userId!!}
                />
              )}
            />
          )}

          {paginator && (
            <Pagination
              count={paginator.totalElements}
              onChangePage={(page) => setCurrentPage(page)}
              current={currentPage}
              perPage={perPage}
              onChangePerPage={(value) => setPerPage(value)}
              isLoading={isPaginationLoading}
              isVisible={isSortLoaded}
            />
          )}
        </>
      )}
    </>
  );
};

export { UsersPage };
