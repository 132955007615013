import React from 'react';
import { PageTemplate } from 'components/templates/PageTemplate';
import { config } from 'pages/config';
import { IPageBuilderConfig } from 'models/route';
import { Layout } from 'components/templates/Layout';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { AuthProvider } from 'state/contexts/auth';
import { PasswordProvider } from 'state/contexts/password';
import { AlertProvider } from 'state/contexts/alert';
import { TrustedDeviceProvider } from 'state/contexts/trustedDevice';
import { DictionaryProvider } from 'state/contexts/dictionary';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { AxiosInterceptor } from 'utils/apiClient';
import { GlobalPropertyProvider } from 'state/contexts/globalProperty';

export const history = createBrowserHistory();

const App = () => (
  <HistoryRouter history={history}>
    <AuthProvider>
      <PasswordProvider>
        <TrustedDeviceProvider>
          <DictionaryProvider>
            <AlertProvider>
              <AxiosInterceptor />
              <GlobalPropertyProvider>
                <Layout>
                  <PageTemplate config={config as IPageBuilderConfig[]} />
                </Layout>
              </GlobalPropertyProvider>
            </AlertProvider>
          </DictionaryProvider>
        </TrustedDeviceProvider>
      </PasswordProvider>
    </AuthProvider>
  </HistoryRouter>
);
export default App;
