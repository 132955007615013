export enum ESearchUserFormLabelsTypes {
  email = 'Email',
  firstName = 'First Name',
  lastName = 'Last Name',
  phoneNumber = 'Phone number',
  status = 'Status',
  role = 'Role',
}

export enum ESearchLicencesFormLabelsTypes {
  licenceId = 'Licence ID',
  purchaserName = 'Purchaser',
  countryUniqueId = 'Country',
}
