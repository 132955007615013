import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { IFormElementOptionProp } from 'models/form';
import { StyledCurrentPageMobile } from './styles';

interface ICurrentPageMobile {
  count: number;
  perPage: number;
  page: number;
  onChange: (arg: number) => void;
}

interface IFormValues {
  page: string;
}

const CurrentPageMobile = ({ count, perPage, page, onChange }: ICurrentPageMobile) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IFormValues>({ defaultValues: { page: page.toString() } });

  const mobileDropdownOptions: IFormElementOptionProp[] = [
    ...Array(Math.ceil(count / perPage)).keys(),
  ].map((option) => ({
    label: (option + 1).toString(),
    value: option + 1,
  }));

  const watchPage = watch('page');

  useEffect(() => {
    if (page !== +watchPage) {
      onChange(+watchPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPage]);

  return (
    <StyledCurrentPageMobile
      label="Current page"
      name="page"
      options={mobileDropdownOptions}
      control={control}
      errors={errors}
      setValue={setValue}
      withValidation={false}
    />
  );
};

export { CurrentPageMobile };
