import { EDictionaryTypes, ESpecificDictionaryTypes } from 'models/dictionary';

export const ApiEndpoints = {
  LOG_IN: (): string => 'auth/login',
  LOG_OUT: (): string => 'auth/logout',
  REFRESH_AUTH_TOKEN: (): string => 'auth/refresh',
  GET_ROLES: (): string => 'role',
  CHANGE_FORGOT_PASSWORD_RECAPTCHA: (query: string): string => `auth/recaptcha${query}`,
  FORGOT_PASSWORD: (): string => 'auth/password/forgot',
  CHANGE_PASSWORD: (queryParam: string): string => `auth/password/change${queryParam}`,
  ACCOUNT_CHANGE_PASSWORD: (): string => 'auth/password',
  GENERATE_PASSWORD: (): string => 'auth/password/generate',
  GENERATE_AUTH_CODE: (email: string): string => `auth/${email}/generate-authentication-code`,
  VERIFY_AUTH_CODE: (): string => 'auth/verify',
  ADD_TRUSTED_DEVICE: (): string => 'accounts/trusted-device',
  FETCH_TRUSTED_DEVICES: (): string => 'accounts/trusted-device',
  DELETE_TRUSTED_DEVICE: (id: string) => `accounts/trusted-device/${id}`,
  GET_ACCOUNT: (id: string): string => `accounts/${id}`,
  GET_USER_SETTING: (id: string): string => `user-settings/${id}`,
  UNBAN_USER: (id: string): string => `auth/unban/${id}`,
  UPDATE_USER_SETTING: (id: string): string => `user-settings/${id}`,
  GET_DICTIONARY: (type: EDictionaryTypes): string => `dictionary/${type}/read-all`,
  GET_SPECIFIC_DICTIONARY: (type: ESpecificDictionaryTypes): string => `dictionary/${type}`,
  GET_USERS: (): string => 'accounts',
  CREATE_USER: (): string => 'accounts',
  GET_USER: (id: string): string => `accounts/${id}`,
  UPDATE_USER: (id: string): string => `accounts/${id}`,
  ACTIVATE_USER: (id: string): string => `accounts/${id}/active`,
  GET_LICENCES: (): string => '/licence/search',
  GET_LICENCE: (id: string): string => `/licence/${id}`,
  CREATE_LICENCE: (): string => '/licence',
  UPDATE_LICENCE: (id: string): string => `/licence/${id}`,
  UPDATE_LICENCE_PASSWORD: (id: string): string => `/licence/${id}/change/password`,
  LICENCE_GENERATE_PASSWORD: (): string => 'licence/password',
  REPLACE_LICENCE: (id: string): string => `/licence/${id}`,
  GET_LICENCE_KEY: (id: string): string => `/licence/${id}/key`,
  GET_GLOBAL_PROPERTIES: (): string => 'global-property',
  GET_GLOBAL_PROPERTIES_BY_KEY: (key: string): string => `/global-property/${key}`,
  GET_TRANSLATIONS_LANGUAGES: (): string => 'languages',
  GET_TRANSLATION: (id: string): string => `languages/${id}`,
  GET_TRANSLATION_DEFAULT: (): string => 'languages/default',
  CREATE_TRANSLATION: (): string => 'languages',
  UPDATE_TRANSLATION: (id: string): string => `languages/${id}`,
  UPDATE_TRANSLATION_FILE: (id: string): string => `languages/${id}/key-translation`,
  DOWNLOAD_TRANSLATION_FILE: (id: string): string =>
    `languages/${id}/key-translation/download`,
  DOWNLOAD_DEFAULT_TRANSLATION_FILE: (): string =>
    'languages/key-translation/download-template',
  SEND_EMAIL_LICENCE: (id: string) => `/licence/${id}/send-email`,
  VALIDATE_RESET_PASSWORD_LINK: (): string => 'auth/password/validateLink',
};
