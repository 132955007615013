import React, { ReactNode } from 'react';
import { isFunction } from 'helpers/guards';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';

interface ICardList {
  items: any[];
  render: (data: any, index: number) => ReactNode;
}

const CardList = ({ items, render }: ICardList) => {
  if (items.length) {
    return (
      <>{items.map((item, index) => (isFunction(render) ? render(item, index) : null))}</>
    );
  }
  return (
    <Typography variant="subtitle2" mb={2} textAlign="center" color={colors.primary100}>
      No Results
    </Typography>
  );
};

export { CardList };
