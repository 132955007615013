import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { ITranslation } from 'models/translation';
import { formatDate } from 'utils/date';

export const mapTranslationsToDataSource = (roles: ITranslation[]): ITableDataSource[] =>
  roles.map((translation) => ({
    id: translation.uniqueId,
    language: translation.name,
    nativeLanguage: translation.nativeName,
    createdDate: translation.createdDate,
    lastModifiedDate: translation.lastModifiedDate,
  }));

interface ITranslationsTableColumns {
  handleEdit: (arg: string) => void;
  dateFormat: string;
}

export const translationsTableColumns = ({
  handleEdit,
  dateFormat,
}: ITranslationsTableColumns): ITableColumns[] => [
  {
    id: '4eaea4ee-d36f-400b-895b-231c81f091cf',
    key: 'language',
    isSortable: false,
    title: 'Language',
    render: (_data, props) => `${props.language} / ${props.nativeLanguage}`,
  },
  {
    id: '4eaea4ee-d36f-460b-895b-231c81f091cf',
    key: 'createdDate',
    isSortable: false,
    title: 'Creation Date',
    render: (_data, props) =>
      props.createdDate ? formatDate(props.createdDate, dateFormat) : '-',
  },
  {
    id: '4eaea4ee-d36f-460b-895b-231c81f091cf2',
    key: 'lastModifiedDate',
    isSortable: false,
    title: 'Last Update',
    render: (_data, props) =>
      props.lastModifiedDate ? formatDate(props.lastModifiedDate, dateFormat) : '-',
  },
  {
    id: '4eaea4ee-d36f-500b-895b-231c81f091cf',
    key: '',
    title: 'Action',
    isSortable: false,
    width: '110px',
    align: 'center',
    render: (_data, props) => (
      <Button
        variant={EButtonVariants.outlined}
        tableButton={true}
        icon={EIconTypes.edit}
        onClick={() => handleEdit(props.id)}
      />
    ),
  },
];
