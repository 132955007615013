import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { UserForm } from 'pages/UsersPage/UserForm';
import { useNavigate } from 'react-router-dom';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useUsers } from 'pages/UsersPage/hooks';
import { useAlert } from 'models/alertContext';

const AddUserPage = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<IUserFormValues | null>();
  const { handleCreate } = useUsers();
  const { setAlert, clearAlert } = useAlert();

  const handleSubmit = async (data: IUserFormValues) => {
    try {
      await handleCreate(data);
      navigate(ERouteLinks.Users);
    } catch (e: any) {
      setAlert(ActionTypes.SET_USER_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  useEffect(() => {
    const formInitialValues = { ...initialUserFormValues };
    setInitialValues(formInitialValues);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs items={[{ label: 'Users', route: ERouteLinks.Users }, 'Add User']} />
      <StyledPageTitle variant="h4">Add User</StyledPageTitle>

      <TableFormWrapper>
        <UserForm
          onCancel={() => navigate(ERouteLinks.Users)}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
        />
      </TableFormWrapper>
    </>
  );
};

export { AddUserPage };
