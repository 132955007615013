import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { formatDate } from 'utils/date';

interface ITranslationTableCard {
  data: ITableDataSource;
  editTranslations: (id: string) => void;
  dateFormat: string;
}

const TranslationTableCard = ({
  data,
  editTranslations,
  dateFormat,
}: ITranslationTableCard) => (
  <Grid
    container
    sx={{
      border: `1px solid ${colors.gray200}`,
      borderRadius: '4px',
      overflow: 'hidden',
      mt: 1,
      backgroundColor: colors.gray100,
    }}
  >
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Language
      </Typography>
      <Typography variant="body2">{data.language}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Creation Date
      </Typography>
      <Typography variant="body2">{formatDate(data.creationDate, dateFormat)}</Typography>
    </Grid>
    <Grid item xs={6} lg={3} p={2}>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        Last Update
      </Typography>
      <Typography variant="body2">{formatDate(data.lastModifiedDate, dateFormat)}</Typography>
    </Grid>
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        m: {
          xs: 2,
          lg: 2,
        },
      }}
      spacing={2}
    >
      <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
          Actions
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={9} justifyContent="flex-end" columnGap={3}>
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Button
            label="Edit"
            variant={EButtonVariants.outlined}
            onClick={() => editTranslations(data.id as string)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export { TranslationTableCard };
