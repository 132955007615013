import styled from '@emotion/styled';
import { Grid, Typography, css } from '@mui/material';
import { colors } from 'theme/colors';

interface IStyledFormLabel {
  disabled?: boolean;
}

export const StyledFormElementWrapper = styled.div<IStyledFormLabel>`
  display: flex;
  flex-direction: column;
  ${(props: IStyledFormLabel) =>
    props.disabled &&
    css`
      color: ${colors.gray400};
    `}
`;

export const StyledPageTitle = styled(Typography)`
  color: ${colors.primary400};
  margin-bottom: 10px;
`;

export const StyledAdvancedSearch = styled(Grid)`
  margin-top: 10px;
  margin-bottom: 5px;
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;

export const StyledForm = styled(Grid)`
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;
