import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/globalProperty';
import { getGlobalPropertiesByKey } from 'requests/globalProperty';
import { EActionTypes } from 'state/actions/globalProperty';
import { GlobalPropertyContext } from 'models/globalPropertyContext';
import { EGlobalPropertyTypes } from 'models/globalProperty';

export const GlobalPropertyProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const getDateFormat = async () => {
    const dateFormat = await getGlobalPropertiesByKey(EGlobalPropertyTypes.DateFormat);
    if (dateFormat) {
      dispatch({ type: EActionTypes.GET_DATE_FORMAT, payload: dateFormat.value });
    } else {
      dispatch({ type: EActionTypes.GET_DATE_FORMAT, payload: null });
    }
  };

  const value = useMemo(() => ({ ...state, getDateFormat }), [state]);

  return (
    <GlobalPropertyContext.Provider value={value}>{children}</GlobalPropertyContext.Provider>
  );
};
