import React, { ReactElement, useEffect, useState } from 'react';
import { LoginPage } from 'pages/LoginPage';
import { TwoFactorAuthPage } from 'pages/TwoFactorAuthPage';
import { FC_MANAGER_AUTH_ACCESS_TOKEN } from 'requests/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { LicencesPage } from 'pages/LicencesPage';

export interface IData {
  email: string;
  password: string;
  phoneNumber: string;
}

const AuthPage = (): ReactElement => {
  const [isLoading, setLoading] = useState(true);
  const [formStep, setFormStep] = useState(1);
  const [data, setData] = useState<IData>({ email: '', password: '', phoneNumber: '' });
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();

  useEffect(() => {
    if (localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN)) {
      navigate(ERouteLinks.Licences);
    }
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN) !== null) {
      setFormStep(3);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (routerState?.isRedirectToTwoFactorAuthPage) {
      setData((prevState: IData) => ({ ...prevState, email: routerState?.email }));
      setFormStep(2);
    }
  }, []);

  useEffect(() => () => window.history.replaceState({}, document.title), []);

  const renderAuthPage = () => {
    switch (formStep) {
      case 1:
        return (
          <LoginPage
            changePage={(step) => setFormStep(step)}
            changeDataCallback={(d) => setData(d)}
          />
        );
      case 2:
        return (
          <TwoFactorAuthPage
            email={data.email}
            password={data.password}
            phoneNumber={data.phoneNumber}
            changeDataCallback={(d) => setData(d)}
          />
        );
      case 3:
        return <LicencesPage />;
      default:
        return <div />;
    }
  };

  return <div>{!isLoading && renderAuthPage()}</div>;
};
export { AuthPage };
