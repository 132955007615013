import { IPaginator } from 'models/paginator';

export enum EUserStatus {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export interface IUser {
  uniqueId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: {
    codeDictionaryUniqueId: string;
    value: string;
  };
  active: boolean;
  blocked: boolean;
  status: boolean;
  lastLoginDate: string;
  created: string;
  assignedLegalIds: string[];
  trustedDeviceIds: string[];
  password: string;
}

export interface IUsersResponse extends IPaginator {
  content: IUser[];
}
