import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledHeading = styled.div`
  background-color: ${colors.primary15};
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 20px 0;
`;
