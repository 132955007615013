import { IAddTrustedDeviceBodyRequest, IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { IApiClientResponse, TResponse, isResponseError } from 'models/apiClient';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { handleRestApiException } from 'utils/handleRestApiException';

const addTrustedDeviceRequest = async (
  body: IAddTrustedDeviceBodyRequest,
): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<IAddTrustedDeviceResponse>>(
      ApiEndpoints.ADD_TRUSTED_DEVICE(),
      body,
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const addTrustedDevice = async (
  dispatch: any,
  body: IAddTrustedDeviceBodyRequest,
  successCallback: () => void,
): Promise<TResponse<IAddTrustedDeviceResponse>> => {
  const res = await addTrustedDeviceRequest(body);

  try {
    if (res.success) {
      successCallback();
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

const fetchTrustedDeviceRequest = async (): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.get<TResponse<Array<IAddTrustedDeviceResponse>>>(
      ApiEndpoints.FETCH_TRUSTED_DEVICES(),
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const fetchTrustedDevices = async (
  successCallback: (res: Array<IAddTrustedDeviceResponse>) => void,
): Promise<TResponse<IAddTrustedDeviceResponse>> => {
  const res = await fetchTrustedDeviceRequest();

  try {
    if (res.success) {
      successCallback(res.data);
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

export const removeTrustedDevice = async (id: string): Promise<boolean> => {
  try {
    const { data } = await apiClient.delete(ApiEndpoints.DELETE_TRUSTED_DEVICE(id));

    return !isResponseError(data);
  } catch (e) {
    return false;
  }
};
