export const DictionaryActionTypes = {
  SET_GENDER: 'SET_GENDER',
  SET_ORGANIZATION_TYPE: 'SET_ORGANIZATION_TYPE',
  SET_LEGAL_VISIBILITY: 'SET_LEGAL_VISIBILITY',
  SET_MAKE: 'SET_MAKE',
  SET_MODEL: 'SET_MODEL',
  SET_CALIBER: 'SET_CALIBER',
  SET_STATE: 'SET_STATE',
  SET_REGION: 'SET_REGION',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_PHONE_CODE: 'SET_PHONE_CODE',
  SET_ADDRESS_TYPE: 'SET_ADDRESS_TYPE',
  SET_LEGALITY: 'SET_LEGALITY',
  SET_MANUFACTURER: 'SET_MANUFACTURER',
  SET_FIREARM_STATE: 'SET_FIREARM_STATE',
};
