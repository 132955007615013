import { IUser } from 'models/user';
import { IPaginator } from 'models/paginator';
import { useState } from 'react';
import { EPerPages, ISortOptions } from 'models/table';
import { ISearchUserFormValues, IUserFormValues } from 'models/form';
import { createUser, getUsers, updateUser } from 'requests/user';
import { useDictionary } from 'models/dictionaryContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';

export const useUsers = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const { phoneCode, getPhoneCode } = useDictionary();
  const { setAlert } = useAlert();

  const getUsersData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<ISearchUserFormValues> = {},
    sort?: ISortOptions | null,
  ) => {
    const usersData = await getUsers(currentPage, perPage, query, sort);

    if (usersData) {
      const { content, ...paginatorData } = usersData;
      setUsers(content);
      setPaginator(paginatorData);
    } else {
      setUsers([]);
      setPaginator(null);
    }
  };

  const setUserBlocked = async (id: string) => {
    const { data } = await apiClient.put(ApiEndpoints.UNBAN_USER(id));

    if (isResponseError(data)) {
      return null;
    }

    return true;
  };

  const handleCreate = async (data: IUserFormValues) => {
    data.password = `Fake!${Math.floor(Math.random() * 10000)}!Password`;

    const { phoneNumberCodeDictionaryUniqueId, phoneNumberValue, ...rest } = data;
    const phoneNumber = {
      codeDictionaryUniqueId: phoneNumberCodeDictionaryUniqueId,
      value: phoneNumberValue,
    };
    const response = await createUser({ ...rest, phoneNumber });
    if (response.status === 200) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'User has been created successfully.',
      });
    } else if (response.status === 400) {
      await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: 'The entered user email is already in use.',
        variant: EAlertVariants.error,
      });
    }

    return response;
  };

  const handleEdit = async (id: string, data: IUserFormValues) => {
    const { phoneNumberCodeDictionaryUniqueId, phoneNumberValue, password, ...rest } = data;
    const phoneNumber = {
      codeDictionaryUniqueId: phoneNumberCodeDictionaryUniqueId,
      value: phoneNumberValue,
    };
    const response = await updateUser(id, { ...rest, phoneNumber });
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'User has been updated successfully.',
      });
    }
    return response;
  };

  const loadDictionaries = async () => {
    if (!phoneCode) {
      getPhoneCode();
    }
  };

  return {
    users,
    setUsers,
    paginator,
    getUsersData,
    loadDictionaries,
    handleCreate,
    handleEdit,
    setUserBlocked,
  };
};
