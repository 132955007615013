export const ActionTypes = {
  SET_AUTH_ALERT: 'SET_AUTH_ALERT',
  CLEAR_AUTH_ALERT: 'CLEAR_AUTH_ALERT',
  SET_SAME_PAGE_ALERT: 'SET_SAME_PAGE_ALERT',
  CLEAR_SAME_PAGE_ALERT: 'CLEAR_SAME_PAGE_ALERT',
  SET_NEXT_PAGE_ALERT: 'SET_NEXT_PAGE_ALERT',
  CLEAR_NEXT_PAGE_ALERT: 'CLEAR_NEXT_PAGE_ALERT',
  SET_USER_ALERT: 'SET_USER_ALERT',
  CLEAR_USER_ALERT: 'CLEAR_USER_ALERT',
  SET_TRUSTED_DEVICE_ALERT: 'SET_TRUSTED_DEVICE_ALERT',
  CLEAR_TRUSTED_DEVICE_ALERT: 'CLEAR_TRUSTED_DEVICE',
};
