import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

const useLicencesFormSchema = (isEdit: boolean) => {
  if (!isEdit) {
    return yup
      .object({
        purchaserName: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        countryUniqueId: yup.string().required('This field is required.'),
        contactJobTitle: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        contactFullName: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        contactDepartment: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        contactEmail: yup
          .string()
          .email('Email format is invalid.')
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        contactPhone: yup
          .string()
          .required('This field is required.')
          .max(15, 'This field should have max 15 characters.')
          .trim(),
        comments: yup
          .string()
          .nullable()
          .max(80, 'This field should have max 80 characters.')
          .trim(),

        licenceType: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        startDate: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        expiryDate: yup.string().when('startDate', {
          is: (date: any) => !!date,
          then: yup
            .string()
            .test(
              'date-validation',
              'Expiry date cannot be before start date.',
              (value, context) => {
                if (!value) {
                  return true;
                }
                return (
                  new Date(value).valueOf() > new Date(context.parent.startDate).valueOf()
                );
              },
            ),
        }),
        numberOfUsers: yup
          .string()
          .test(
            'number-validation',
            'Number of Users cannot be less than 1.',
            (value: any) => {
              if (!value || typeof value !== 'string') {
                return true;
              }
              return Number(value) > 0;
            },
          ),
        licenceAdministratorEmail: yup
          .string()
          .email('Email format is invalid.')
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        licenceAdministratorPassword: yup.string().required('This field is required.').trim(),
        licenceFirstName: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        licenceLastName: yup
          .string()
          .required('This field is required.')
          .max(80, 'This field should have max 80 characters.')
          .trim(),
        licencePhonePrefixDictionaryUniqueId: yup
          .string()
          .required('This field is required.')
          .trim(),
        licencePhoneNumber: yup
          .string()
          .required('This field is required')
          .matches(new RegExp(ERegExpTypes.onlyDigits), 'Entered value is not correct.')
          .max(15, 'This field should have max 15 characters.')
          .min(5, 'This field should have min 5 characters.')
          .trim(),
        defaultLanguageId: yup.string().required('This field is required.').trim(),
        languagesIds: yup.array(),
        languagesIdsTmp: yup.object(),
      })
      .test('translationsTest', '', (obj) => {
        if (Object.values(obj.languagesIdsTmp).filter((item) => !!item).length) {
          return true;
        }

        return new yup.ValidationError('This field is required.', null, 'translations');
      });
  }
  return yup
    .object({
      purchaserName: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      countryUniqueId: yup.string().required('This field is required.'),
      contactJobTitle: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      contactFullName: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      contactDepartment: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      contactEmail: yup
        .string()
        .email('Email format is invalid.')
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      contactPhone: yup
        .string()
        .required('This field is required.')
        .max(15, 'This field should have max 15 characters.')
        .trim(),
      comments: yup
        .string()
        .nullable()
        .max(80, 'This field should have max 80 characters.')
        .trim(),

      licenceType: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      startDate: yup
        .string()
        .required('This field is required.')
        .max(80, 'This field should have max 80 characters.')
        .trim(),
      expiryDate: yup.string().when('startDate', {
        is: (date: any) => !!date,
        then: yup
          .string()
          .test(
            'date-validation',
            'Expiry date cannot be before start date.',
            (value, context) => {
              if (!value) {
                return true;
              }
              return new Date(value).valueOf() > new Date(context.parent.startDate).valueOf();
            },
          ),
      }),
      numberOfUsers: yup
        .string()
        .test('number-validation', 'Number of Users cannot be less than 1.', (value: any) => {
          if (!value || typeof value !== 'string') {
            return true;
          }
          return Number(value) > 0;
        }),
      licenceAdministratorPassword: yup.string().trim(),
      defaultLanguageId: yup.string().required('This field is required.').trim(),
      languagesIds: yup.array(),
      languagesIdsTmp: yup.object(),
    })
    .test('translationsTest', '', (obj) => {
      if (Object.values(obj.languagesIdsTmp).filter((item) => !!item).length) {
        return true;
      }

      return new yup.ValidationError('This field is required.', null, 'translations');
    });
};

const useReplaceLicencesFormSchema = () => {
  const schema = yup.object({
    licenceType: yup
      .string()
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    startDate: yup
      .string()
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    expiryDate: yup.string().when('startDate', {
      is: (date: any) => !!date,
      then: yup
        .string()
        .test(
          'date-validation',
          'Expiry date cannot be before start date.',
          (value, context) => {
            if (!value) {
              return true;
            }
            return new Date(value).valueOf() > new Date(context.parent.startDate).valueOf();
          },
        ),
    }),
    numberOfUsers: yup
      .string()
      .test('number-validation', 'Number of Users cannot be less than 1.', (value: any) => {
        if (!value || typeof value !== 'string') {
          return true;
        }
        return Number(value) > 0;
      }),
    licenceAdministratorEmail: yup
      .string()
      .email('Email format is invalid.')
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    licenceAdministratorPassword: yup.string().required('This field is required.').trim(),
    licenceFirstName: yup
      .string()
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    licenceLastName: yup
      .string()
      .required('This field is required.')
      .max(80, 'This field should have max 80 characters.')
      .trim(),
    licencePhonePrefixDictionaryUniqueId: yup
      .string()
      .required('This field is required.')
      .trim(),
    licencePhoneNumber: yup
      .string()
      .required('This field is required.')
      .matches(new RegExp(ERegExpTypes.onlyDigits), 'Entered value is not correct.')
      .max(15, 'This field should have max 15 characters.')
      .min(5, 'This field should have min 5 characters.')
      .trim(),
  });

  return { schema };
};

export { useLicencesFormSchema, useReplaceLicencesFormSchema };
