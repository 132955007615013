import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { useAlert } from 'models/alertContext';
import { IReplaceLicencesFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { StyledHeading } from '../styles';
import { useLicences } from '../hooks';
import { ReplaceLicenceForm } from '../ReplaceLicenceForm';

const ReplaceLicencePage = () => {
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { id } = useParams<{ id: string }>();

  const { getLicenceData, licence, handleReplaceLicence } = useLicences();
  const { shortDateFormat } = useGlobalProperty();

  useEffect(() => {
    (async function getLicencesDataInit() {
      if (id) {
        await getLicenceData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleReplace = async (data: IReplaceLicencesFormValues) => {
    try {
      if (id) {
        await handleReplaceLicence(id, data);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: 'Licence has been replaced successfully.',
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.Licences);
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  const licenceDetailColumn = licence && licence.expiryDate ? 12 / 5 : 3;

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'Licences', route: ERouteLinks.Licences }, 'Replace Licence']}
      />
      {licence && id && (
        <>
          <Typography variant="h5" color={colors.primary900}>
            Replace Licence
          </Typography>
          <Grid
            container
            sx={{
              marginTop: '15px',
            }}
          >
            <StyledHeading>
              <Typography variant="h5" color={colors.primary900}>
                Current Licence Details
              </Typography>
            </StyledHeading>
            <Grid
              container
              sx={{
                border: `1px solid ${colors.primary25}`,
                borderRadius: '4px',
                overflow: 'hidden',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: colors.primary25,
                }}
                px={2}
                py={1}
              >
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} md={12} lg={3}>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {licence.licenceId}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container p={2} rowSpacing={2}>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Licence ID
                  </Typography>
                  <Typography variant="body2">{licence.licenceId}</Typography>
                </Grid>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Licence Type
                  </Typography>
                  <Typography variant="body2">
                    {licence.licenceType && licence.licenceType.ONLINE && (
                      <Chips
                        text={licence.licenceType.ONLINE}
                        variant={EChipsVariants.warning}
                      />
                    )}
                    {licence.licenceType && licence.licenceType.OFFLINE && (
                      <Chips
                        text={licence.licenceType.OFFLINE}
                        variant={EChipsVariants.primary}
                      />
                    )}
                  </Typography>
                </Grid>
                {licence.expiryDate && (
                  <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      Start Date
                    </Typography>
                    <Typography variant="body2">
                      {licence.startDate
                        ? formatDate(licence.startDate, shortDateFormat)
                        : '-'}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Expiry Date
                  </Typography>
                  <Typography variant="body2">
                    {licence.expiryDate ? (
                      formatDate(licence.expiryDate, shortDateFormat)
                    ) : (
                      <Chips text="perpetual" variant={EChipsVariants.success100} />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} lg={licenceDetailColumn} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    Number of Users
                  </Typography>
                  <Typography variant="body2">
                    {licence.numberOfUsers || (
                      <Chips text="Unlimited" variant={EChipsVariants.success100} />
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <ReplaceLicenceForm
            onSubmit={handleReplace}
            licenceType={licence?.licenceType.ONLINE ? 'ONLINE' : 'OFFLINE'}
          />
        </>
      )}
    </>
  );
};

export { ReplaceLicencePage };
