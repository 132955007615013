import { DashboardPage } from 'pages/DashboardPage';
import { EPageComponentTypes } from 'models/route';
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { AuthPage } from 'pages/AuthPage';
import { LicencesPage } from 'pages/LicencesPage';
import { UsersPage } from 'pages/UsersPage';
import { TranslationsPage } from 'pages/TranslationsPage';
import { AccountPage } from 'pages/AccountPage';
import { RemoveDevicePage } from 'pages/AccountPage/RemoveDevicePage';
import { ChangePasswordPage } from 'pages/AccountPage/ChangePasswordPage';
import { AddUserPage } from 'pages/UsersPage/AddUserPage';
import { EditUserPage } from 'pages/UsersPage/EditUserPage';
import { ChangeStatusUserPage } from 'pages/UsersPage/ActivateUserPage';
import { EditAccountPage } from 'pages/AccountPage/EditAccountPage';
import { AccessDeniedPage } from 'pages/AccessDeniedPage';
import { ServerErrorPage } from 'pages/ServerErrorPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { AddLicencePage } from 'pages/LicencesPage/AddLicencePage';
import { EditLicencePage } from 'pages/LicencesPage/EditLicencePage';
import { DetailLicencePage } from 'pages/LicencesPage/DetailLicencePage';
import { ReplaceLicencePage } from 'pages/LicencesPage/ReplaceLicencePage';
import { AddTranslationPage } from 'pages/TranslationsPage/AddTranslationPage';
import { EditTranslationPage } from 'pages/TranslationsPage/EditTranslationPage';
import { UpdateSystemKeysPage } from 'pages/TranslationsPage/UpdateSystemKeysPage';

export const PageComponentTypes = {
  [EPageComponentTypes.AuthPage]: AuthPage,
  [EPageComponentTypes.AccountPage]: AccountPage,
  [EPageComponentTypes.ForgotPasswordPage]: ForgotPasswordPage,
  [EPageComponentTypes.ResetPasswordPage]: ResetPasswordPage,
  [EPageComponentTypes.ResetPasswordLink]: ResetPasswordPage,
  [EPageComponentTypes.DashboardPage]: DashboardPage,
  [EPageComponentTypes.NotFoundPage]: NotFoundPage,
  [EPageComponentTypes.AccessDeniedPage]: AccessDeniedPage,
  [EPageComponentTypes.ServerErrorPage]: ServerErrorPage,
  [EPageComponentTypes.LicencesPage]: LicencesPage,
  [EPageComponentTypes.UsersPage]: UsersPage,
  [EPageComponentTypes.AddUserPage]: AddUserPage,
  [EPageComponentTypes.AddLicencePage]: AddLicencePage,
  [EPageComponentTypes.EditLicencePage]: EditLicencePage,
  [EPageComponentTypes.DetailLicencePage]: DetailLicencePage,
  [EPageComponentTypes.ReplaceLicencePage]: ReplaceLicencePage,
  [EPageComponentTypes.EditUserPage]: EditUserPage,
  [EPageComponentTypes.ChangeStatusUserPage]: ChangeStatusUserPage,
  [EPageComponentTypes.TranslationsPage]: TranslationsPage,
  [EPageComponentTypes.AddTranslationPage]: AddTranslationPage,
  [EPageComponentTypes.EditTranslationPage]: EditTranslationPage,
  [EPageComponentTypes.UpdateSystemKeysPage]: UpdateSystemKeysPage,
  [EPageComponentTypes.RemoveDevicePage]: RemoveDevicePage,
  [EPageComponentTypes.ChangePasswordPage]: ChangePasswordPage,
  [EPageComponentTypes.AccountEditPage]: EditAccountPage,
};
