import { createContext, useContext } from 'react';
import { IAuthBodyRequest, IAuthResponse, IVerifyAuthCodeBodyRequest } from 'models/auth';
import { IAlert } from 'components/atoms/Alert';

export interface IUser {
  userId: string | null;
  email: string | null;
  firstName?: string;
  lastName?: string;
}

export interface IAuthContext {
  accessToken: string | null;
  refreshToken: string | null;
  logIn: (body: IAuthBodyRequest, p?: (res: IAuthResponse) => void) => void;
  logOut: (p: () => void) => void;
  isLogOutLoading: boolean;
  authMessage: IAlert;
  isLoginLoading: boolean;
  changeAccessToken: (ssx: string) => void;
  verifyAuthCode: (
    body: IVerifyAuthCodeBodyRequest,
    o: () => void,
    p: (response: any) => void,
  ) => void;
  clearAuthErrors: () => void;
  user: IUser | null;
  getUser: () => void;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuth = (): IAuthContext => useContext(AuthContext);
