import { IUserSettingRequest, IUserSettingResponse } from 'models/userSetting';
import { useState } from 'react';
import { changePassword, getUserSetting, updateUserSetting } from 'requests/userSetting';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';

export const useUserSetting = () => {
  const [userSetting, setUserSetting] = useState<IUserSettingResponse>();
  const { setAlert } = useAlert();

  const getUserSettingData = async (id: string) => {
    const data = await getUserSetting(id);
    if (data) {
      setUserSetting(data);
    }
  };

  const updateUserSettingData = async (userId: string, accountData: IUserSettingRequest) => {
    const response = await updateUserSetting(userId, accountData);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'The user data has been saved successfully.',
      });
    }
    return response;
  };

  const changeAccountPassword = async (currentPassword: string, newPassword: string) => {
    const result = await changePassword(currentPassword, newPassword);
    if (result) {
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: 'Password has been saved successfully.',
      });
    }
    return result;
  };

  return { userSetting, getUserSettingData, changeAccountPassword, updateUserSettingData };
};
