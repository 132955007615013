import { createContext, useContext } from 'react';
import { IDictionary } from 'models/dictionary';

export interface IDictionaryContext {
  gender: IDictionary[] | null;
  getGender: () => void;
  organizationType: IDictionary[] | null;
  getOrganizationType: () => void;
  legalVisibility: IDictionary[] | null;
  getLegalVisibility: () => void;
  make: string[] | null;
  getMake: () => void;
  model: string[] | null;
  getModel: () => void;
  caliber: string[] | null;
  getCaliber: () => void;
  state: IDictionary[] | null;
  getState: () => void;
  region: IDictionary[] | null;
  getRegion: () => void;
  country: IDictionary[] | null;
  getCountry: () => void;
  phoneCode: IDictionary[] | null;
  getPhoneCode: () => void;
  addressType: IDictionary[] | null;
  getAddressType: () => void;
  legality: IDictionary[] | null;
  getLegality: () => void;
  manufacturer: string[] | null;
  getManufacturer: () => void;
}

export const DictionaryContext = createContext<IDictionaryContext>({} as IDictionaryContext);

export const useDictionary = (): IDictionaryContext => useContext(DictionaryContext);
