import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

const useUserFormSchema = () => {
  const schema = yup.object({
    email: yup
      .string()
      .email('Email format is invalid.')
      .required('This field is required.')
      .max(64, 'This field should have max 64 characters.')
      .trim(),
    firstName: yup
      .string()
      .required('This field is required.')
      .max(64, 'This field should have max 64 characters.')
      .trim(),
    lastName: yup
      .string()
      .required('This field is required.')
      .max(64, 'This field should have max 64 characters.')
      .trim(),
    phoneNumberCodeDictionaryUniqueId: yup.string().required('This field is required.').trim(),
    phoneNumberValue: yup
      .string()
      .required('This field is required.')
      .matches(new RegExp(ERegExpTypes.onlyDigits), 'Entered value is not correct.')
      .max(15, 'This field should have max 15 characters.')
      .min(5, 'This field should have min 5 characters.')
      .trim(),
  });
  return { schema };
};

export default useUserFormSchema;
