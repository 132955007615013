import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${colors.primary500};
`;
