import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box, Typography } from '@mui/material';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useForm } from 'react-hook-form';
import { initialResetPasswordFormValues } from 'models/form';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { schema } from 'pages/ResetPasswordPage/schemaValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserSetting } from 'pages/AccountPage/hooks';

interface IChangePasswordFormValues {
  newPassword: string;
  newPasswordRepeat: string;
  currentPassword: string;
}

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const { changeAccountPassword } = useUserSetting();
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IChangePasswordFormValues>({
    defaultValues: initialResetPasswordFormValues,
    resolver: yupResolver(schema),
  });

  const onSubmitForm = async (data: IChangePasswordFormValues) => {
    if (data.newPassword) {
      setSubmitLoading(true);
      try {
        const response = await changeAccountPassword(data.currentPassword, data.newPassword);
        if (response) {
          navigate(ERouteLinks.Account);
        }
      } catch (e: any) {
        if (e?.response?.data?.message) {
          setError('currentPassword', { type: 'custom', message: e.response.data.message });
        }
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[{ label: 'User Settings', route: ERouteLinks.Account }, 'Change Password']}
      />

      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">Change Password</StyledPageTitle>
      </Box>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container>
          <Grid item xs={6}>
            <Box mb={1}>
              <FormInput
                name="currentPassword"
                label="Current Password*"
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
                isClearable={true}
              />
            </Box>
            <Box mb={1}>
              <FormInput
                name="newPassword"
                label="New Password*"
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
                isClearable={true}
              />
            </Box>
            <Box mb={1}>
              <FormInput
                name="newPasswordRepeat"
                label="Retype New Password*"
                type={EFormInputTypes.password}
                control={control}
                errors={errors}
                isClearable={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
          <Grid item xs={12} lg={6} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                label="Save"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
                sx={{ mr: 1 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label="Cancel"
                variant={EButtonVariants.outlined}
                onClick={() => navigate(ERouteLinks.Account)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>

      <Grid xs={12} item mt={2}>
        <Typography variant="caption">*Mandatory field</Typography>
      </Grid>
    </>
  );
};

export { ChangePasswordPage };
