import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css } from '@mui/material';

interface IStyledWrapper {
  backgroundColor: string;
}

const StyledWrapper = styled.div<IStyledWrapper>`
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 12px;
  border: 1px solid ${colors.error15};
  border-radius: 4px;
  ${(props: IStyledWrapper) =>
    css`
      background-color: ${props.backgroundColor};
    `}
`;
interface IStyledIconWrapper {
  iconBackgroundColor: string;
}

const StyledIconWrapper = styled.div<IStyledIconWrapper>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  height: 32px;
  border-radius: 4px;
  ${(props: IStyledIconWrapper) =>
    css`
      background-color: ${props.iconBackgroundColor};
    `}
`;

export { StyledWrapper, StyledIconWrapper };
