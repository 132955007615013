import { EPerPages, ISortOptions } from 'models/table';
import {
  ILicencesFormValues,
  ILicencesUpdateRequest,
  IReplaceLicencesFormValues,
  ISearchLicencesFormValues,
} from 'models/form';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ILicence, ILicencesResponse } from 'models/licence';
import { ApiEndpoints } from 'models/apiEndpoints';
import { queryStringWithSort } from 'helpers/request';

export interface IGeneratePasswordResponse {
  newPassword: string;
}

const getLicences = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchLicencesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size }, sort);
  const body = { ...query };

  const { data } = await apiClient.post<TResponse<ILicencesResponse>>(
    `${ApiEndpoints.GET_LICENCES()}?${queryString}`,
    body,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLicence = async (id: string): Promise<ILicence | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_LICENCE(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLicenceKey = async (id: string): Promise<string | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_LICENCE_KEY(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createLicences = async (body: ILicencesFormValues) =>
  apiClient.post<TResponse<ILicence>>(ApiEndpoints.CREATE_LICENCE(), body);

const updateLicences = async (uniqueId: string, licencesData: ILicencesUpdateRequest) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_LICENCE(uniqueId), licencesData);

  return !isResponseError(data);
};

const updatePassword = async (uniqueId: string, password: string) => {
  const { data } = await apiClient.post(ApiEndpoints.UPDATE_LICENCE_PASSWORD(uniqueId), {
    licenceAdministratorPassword: password,
  });

  return !isResponseError(data);
};
const generatePassword = async () => {
  const { data } = await apiClient.get<TResponse<string>>(
    ApiEndpoints.LICENCE_GENERATE_PASSWORD(),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const replaceLicence = async (uniqueId: string, body: IReplaceLicencesFormValues) => {
  const { data } = await apiClient.patch(ApiEndpoints.REPLACE_LICENCE(uniqueId), body);

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const sendLicenceEmail = async (id: string) =>
  apiClient.post(ApiEndpoints.SEND_EMAIL_LICENCE(id));

export {
  getLicences,
  getLicence,
  createLicences,
  updateLicences,
  generatePassword,
  updatePassword,
  replaceLicence,
  getLicenceKey,
  sendLicenceEmail,
};
