import { Route, Routes } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { IPageBuilderConfig } from 'models/route';
import { PageComponentTypes } from 'constants/PageComponentTypes';
import { AuthPage } from 'pages/AuthPage';
import { FC_MANAGER_AUTH_ACCESS_TOKEN } from 'requests/auth';

interface IPageBuilder {
  config: IPageBuilderConfig[];
}

const PageTemplate = ({ config }: IPageBuilder) => {
  const redirectRoute = (route: IPageBuilderConfig) => {
    const { isProtected, componentType } = route;
    const accessToken = localStorage.getItem(FC_MANAGER_AUTH_ACCESS_TOKEN);
    const Component = PageComponentTypes[componentType];
    return isProtected && !accessToken ? <AuthPage /> : <Component />;
  };
  const renderRoutes = () =>
    config.map(
      (route: IPageBuilderConfig): ReactElement => (
        <Route key={route.componentType} path={route.route} element={redirectRoute(route)}>
          {route.nestedRoutes?.map(
            (nestedRoute: IPageBuilderConfig): ReactElement => (
              <Route
                key={nestedRoute.componentType}
                path={nestedRoute.route}
                element={redirectRoute(route)}
              />
            ),
          )}
        </Route>
      ),
    );
  document.addEventListener(
    'itemInserted',
    () => {
      renderRoutes();
    },
    false,
  );

  return <Routes>{renderRoutes()}</Routes>;
};

export { PageTemplate };
