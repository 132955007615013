import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';

export const schema = yup.object({
  firstName: yup
    .string()
    .required('This field is required.')
    .max(64, 'Max 64 characters.')
    .trim(),
  lastName: yup
    .string()
    .required('This field is required.')
    .max(64, 'Max 64 characters.')
    .trim(),
  prefix: yup.string().required('This field is required.').trim(),
  phoneNumber: yup
    .string()
    .required('This field is required.')
    .matches(new RegExp(ERegExpTypes.onlyDigits), 'Entered value is not correct.')
    .max(15, 'This field should have max 15 characters.')
    .min(5, 'This field should have min 5 characters.')
    .trim(),
});
