import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { colors } from 'theme/colors';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const StyledTextField = styled(TextField)((props: any) => ({
  fieldset: {
    borderRadius: 4,
  },
  input: {
    fontSize: 14,
    height: 32,
    ...(props.disabled
      ? { backgroundColor: colors.gray100 }
      : { backgroundColor: colors.white }),
    boxSizing: 'border-box',
  },
  textarea: {
    fontSize: 14,
  },
  'input::placeholder, textarea::placeholder': {
    fontSize: 16,
    fontWeight: 400,
    color: `${colors.gray300}`,
    fontFamily: 'NotoSansRegular, sans-serif',
    opacity: 1,
  },
  '& .MuiOutlinedInput-root': {
    height: 32,
    marginBottom: 3,
    ...(props.disabled
      ? { backgroundColor: colors.gray100 }
      : { backgroundColor: colors.white }),

    '& fieldset': {
      borderColor: colors.gray300,
    },
  },
  '& .MuiAutocomplete-input': {
    marginBottom: 4,
    marginTop: '-7px',
    marginLeft: '-5px',
  },
}));

export const StyledDatePicker = styled(DatePicker)(() => ({
  '& ': {
    color: 'red',
  },
}));

export const StyledInnerWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
  height: 32px;

  & .MuiOutlinedInput-root {
    width: 100%;
    height: 32px;
  }

  & .MuiSelect-select {
    height: 32px;
    box-sizing: border-box;
    background-color: ${colors.white};
  }
`;
