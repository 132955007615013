import React from 'react';
import { StyledFormFooterLabel } from './styles';

interface IFormLabel {
  label: string;
}

const FormFooterLabel = ({ label }: IFormLabel) => (
  <StyledFormFooterLabel>{label}</StyledFormFooterLabel>
);

export default FormFooterLabel;
